<template>
    <div class="preview_area show_sidebar" id="workout-plan-wizard" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="plan-wizard-form" class="plan-wizard-form">
                    <div class="step_wpr content_area">
                        <div class="info_bar">
                            <div class="time">Estimated Time: <span>6 min.</span></div>
                            <div class="step_bar2">
                                <ul>
                                    <li @click="toggleStep(1)" :class="{ 'active' : planStep == 1 || planStep > 1}">
                                        <span>1</span>
                                        <h6>Program</h6>
                                    </li>
                                    <li @click="toggleStep(2)" :class="{ 'active' : planStep == 2 || planStep > 2}">
                                        <span>2</span>
                                        <h6>Public Page</h6>
                                    </li>
                                    <li @click="toggleStep(3);" :class="{ 'active' : planStep == 3 || planStep > 3}">
                                        <span>3</span>
                                        <h6>Notifications</h6>
                                    </li>
                                    <li @click="toggleStep(4)" :class="{ 'active' : planStep == 4 || planStep > 4 }">
                                        <span>4</span>
                                        <h6>SEO</h6>
                                    </li>
                                </ul>
                                <div class="total_bar"><span :style="`width:${(100 / 3) * (planStep - 1) }%`"></span></div>
                            </div>
                            <button type="button" class="tutorial_btn" @click="handleTutorialVideo()">Tutorial</button>
                        </div>
                        <ul class="btn_list" v-if="planStep == 2">
                            <li v-if="toggleAll" @click="openAllSection()">Open All</li>
                            <li v-else @click="closeAllSection()">Close All</li>
                        </ul>
                        <KeepAlive>
                            <plan-week-setting :workout-plan="form" :plan-step="planStep" :has-preview="preview" ref="plan-week-setting-component">
                                <div class="section_content w-100 open_area">
                                    <Form @invalid-submit="handleFormInvalidSubmit" @submit="handleSubmit" ref="rename-workout-plan-form">
                                        <div class="workout_info" v-click-outside="closeDescription">
                                            <div class="title_bar">
                                                <div class="inline-edit">
                                                    <h3 class="sub_header" :class="{ 'has-error': errors.name }" v-if="!editWorkoutPlanName" @click="editWorkoutPlanName = true;">
                                                        {{ form.name }}
                                                        <span role="alert" v-if="errors.name" class="text-danger">{{ errors.name }}</span>
                                                    </h3>
                                                    <div class="rename_fld" v-if="editWorkoutPlanName">
                                                        <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="Plan name" rules="required" />
                                                        <ErrorMessage name="name" class="text-danger" />
                                                        <button type="button" class="save_btn" @click="handleRenameWorkoutPlanName()" :disabled="loader"><i class="fa fa-spin fa-spinner" v-if="loader"></i> &nbsp;{{ loader ? 'Saving' : 'Save' }}</button>
                                                    </div>
                                                    <button type="button" class="setting_btn" @click="showDescription = !showDescription;"><i class="fas fa-cog"></i></button>
                                                </div>
                                            </div>
                                            <div class="setting_wpr description" :class="{'show' : showDescription}" v-if="showDescription">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Workout Program Categories <a class="option" @click="createCategory = !createCategory">Create  Category</a></label>
                                                            <div class="field_wpr">
                                                            <multiselect
                                                                v-model="form.categories"
                                                                :options="workoutCategories"
                                                                :searchable="true"
                                                                valueProp="id"
                                                                label="name"
                                                                placeholder="Select Category"
                                                                mode="tags"
                                                                @select="handleAutoSaveForm()"
                                                                @deselect="handleAutoSaveForm()"
                                                            ></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="createCategory" class="form_grp mb-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Category Name</label>
                                                        <Form @submit="handleCreateCategory" v-slot="{ errors }" ref="category-form">
                                                            <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                                                <Field autocomplete="off" type="text" name="name" v-model="categoryForm.name" placeholder="Enter Category Name" rules="required" />
                                                                <span class="suffix" @click="handleCreateCategory()"><i class="fa fa-spin fa-spinner" v-if="categorySaveLoader"></i> {{ categorySaveLoader ? 'Creating' : 'Create' }}</span>
                                                            </div>
                                                            <ErrorMessage name="name" class="text-danger" />
                                                        </Form>
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Description</label>
                                                        <div class="field_wpr" :class="{ 'has-error': errors.product_name }">
                                                            <Field name="description" v-model="form.description" label="description" autocomplete="off" rules="required" >
                                                                <textarea name="description" autocomplete="off" @focusout="handleAutoSaveForm()" cols="30" rows="10" placeholder="Description goes here.." v-model="form.description"></textarea>
                                                            </Field>
                                                        </div>
                                                        <ErrorMessage name="description" class="text-danger" />
                                                    </div>
                                                </div>
                                                <label for="make_private" class="switch_option capsule_btn pt-1 pb-3">
                                                    <h5 class="large">Make Private?<span>Turn On / Off to make it Private or public.</span></h5>
                                                    <input type="checkbox" id="make_private" :true-value="1" :false-value="0" v-model="form.is_private" @change="handleAutoSaveForm();" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </plan-week-setting>
                        </KeepAlive>
                        <div class="content_wpr" v-if="planStep == 2">
                            <div class="section_content w-100 open_area">
                                <div class="section_header">
                                    <h2>Public Page</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp p-0 mt-1 mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Public Url</label>
                                                <div class="field_wpr has_suffix">
                                                    <Field autocomplete="off" type="text" v-model="form.url" name="url" placeholder="https://superfitcoaching.com" readonly />
                                                    <span class="suffix pointer" @click="handleCopy(form.url)">Copy</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="hdrsetting" @click="goTo('hdrsection')">
                                <div class="section_header">
                                    <h2>Header</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_header ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_header ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <label for="display_header" class="switch_option capsule_btn">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="display_header" :true-value="1" :false-value="0" v-model="form.public_settings.display_header" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr outer" v-show="form.public_settings.display_header">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Company Name <a class="reset-default" @click="form.public_settings.company_branding = defaultPlanForm.public_settings.company_branding">Reset to default</a></label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.company_branding }">
                                                            <Field autocomplete="off" type="text" name="company_branding" v-model="form.public_settings.company_branding" placeholder="Company Name" />
                                                        </div>
                                                        <ErrorMessage name="company_branding" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item mt-3">
                                                        <label class="input_label">Header Background Color <a class="reset-default" @click="form.public_settings.header_bgcolor = defaultPlanForm.public_settings.header_bgcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="header_bgcolor" type="text" label="header background color">
                                                            <color-picker v-model="form.public_settings.header_bgcolor"/>
                                                        </Field>
                                                        <ErrorMessage name="header_bgcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item mt-3">
                                                        <label class="input_label">Header Text Color <a class="reset-default" @click="form.public_settings.header_textcolor = defaultPlanForm.public_settings.header_textcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="header_textcolor" type="text" label="header text color">
                                                            <color-picker v-model="form.public_settings.header_textcolor"/>
                                                        </Field>
                                                        <ErrorMessage name="header_textcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                                <ul class="tab_sec mt-2">
                                                    <li @click="headerTab = 'logo'" :class="{ active: headerTab === 'logo' }">Header Logo</li>
                                                    <li @click="headerTab = 'author'" :class="{ active: headerTab === 'author' }">Header Author Image</li>
                                                </ul>
                                                <div class="setting_wpr pt-4">
                                                    <div v-if="headerTab === 'logo'">
                                                        <label for="show_logo" class="switch_option capsule_btn py-4">
                                                            <h5 class="large">Display Logo</h5>
                                                            <input type="checkbox" id="show_logo" :true-value="1" :false-value="0" v-model="form.public_settings.display_logo" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <div v-if="form.public_settings.display_logo">
                                                            <image-library image-type="public-logo" upload-text="Logo" v-model="form.public_settings.logo" />
                                                        </div>
                                                    </div>
                                                    <div v-if="headerTab === 'author'">
                                                        <label for="coach_img" class="switch_option capsule_btn py-4">
                                                            <h5 class="large">Display Author</h5>
                                                            <input type="checkbox" id="coach_img" :true-value="1" :false-value="0" v-model="form.public_settings.display_author" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <div v-if="form.public_settings.display_author">
                                                            <image-library image-type="avatar" :is-avatar="true" upload-text="Image" v-model="form.public_settings.author" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header"  @click="goTo('expvidsection')">
                                    <h2>Plan Explainer Video</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_explainer_video ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_explainer_video ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <label for="explain_video" class="switch_option capsule_btn">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="explain_video" :true-value="1" :false-value="0" v-model="form.public_settings.display_explainer_video" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr mt-3" v-show="form.public_settings.display_explainer_video">
                                                <div class="border-bottom pb-2">
                                                    <label for="video_url" class="switch_option capsule_btn border-0">
                                                        <h5 class="large">Video Link (youtube, vimeo, wistia, screencast-o-matic, loom)</h5>
                                                        <input type="radio" id="video_url" value="0" v-model="form.public_settings.explainer_video_type" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div class="setting_wpr mb-2" v-if="form.public_settings.explainer_video_type == 0">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.explainer_video }">
                                                                    <Field autocomplete="off" type="text" name="explainer_video" v-model="form.public_settings.explainer_video" placeholder="Video Link Goes Here..." rules="url" label="explainer video" />
                                                                    <span class="prefix">URL</span>
                                                                </div>
                                                                <ErrorMessage name="explainer_video" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="py-2">
                                                    <label for="embed_video" class="switch_option capsule_btn border-0">
                                                        <h5 class="large">Embeded Code (youtube, vimeo, wistia, screencast-o-matic, loom)</h5>
                                                        <input type="radio" id="embed_video" value="1" v-model="form.public_settings.explainer_video_type" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div class="setting_wpr" v-if="form.public_settings.explainer_video_type == 1">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.explainer_embed }">
                                                                    <Field autocomplete="off" type="text" name="explainer_embed" v-model="form.public_settings.explainer_embed" label="explainer embed code" :class="{ 'has-error': errors.explainer_embed }">
                                                                        <textarea cols="30" rows="10" v-model="form.public_settings.explainer_embed" placeholder="Embed code goes here.."></textarea>
                                                                    </Field>
                                                                </div>
                                                                <ErrorMessage name="explainer_embed" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>

                            <div class="section_content w-100" ref="ftrsetting">
                                <div class="section_header">
                                    <h2>Optin Setting</h2>
                                    <!-- <span class="status" :style="`color: ${form.public_settings.display_footer ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_footer ? 'On' : 'Off'}}</span> -->
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1 mb-2">
                                        <label for="workout-optin-phone-field" class="switch_option capsule_btn">
                                            <h5>Workout Optin Phone</h5>
                                            <input type="checkbox" id="workout-optin-phone-field" :true-value="1" :false-value="0" v-model="form.has_optin_phone" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Color</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Accent Color <a class="reset-default" @click="form.public_settings.element_color = defaultPlanForm.public_settings.element_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="label_bg_color" type="text" label="label background color">
                                                        <color-picker v-model="form.public_settings.element_color" />
                                                    </Field>
                                                    <ErrorMessage name="label_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Text Color <a class="reset-default" @click="form.public_settings.element_text_color = defaultPlanForm.public_settings.element_text_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="label_text_color" type="text" label="label text color">
                                                        <color-picker v-model="form.public_settings.element_text_color" />
                                                    </Field>
                                                    <ErrorMessage name="label_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Block Background Color <a class="reset-default" @click="form.public_settings.block_bg_color = defaultPlanForm.public_settings.block_bg_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="label_bg_color" type="text" label="label background color">
                                                        <color-picker v-model="form.public_settings.block_bg_color" />
                                                    </Field>
                                                    <ErrorMessage name="label_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Timer Color <a class="reset-default" @click="form.public_settings.timer_color = defaultPlanForm.public_settings.timer_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="label_bg_color" type="text" label="label background color">
                                                        <color-picker v-model="form.public_settings.timer_color" />
                                                    </Field>
                                                    <ErrorMessage name="label_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Submit Button</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div ref="subbtnsetting"  @click="goTo('subbtnsection')">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Button Text <a class="reset-default" @click="form.public_settings.submit_btn_text = defaultPlanForm.public_settings.submit_btn_text">Reset to default</a></label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.name }">
                                                            <Field autocomplete="off" type="text" name="name" v-model="form.public_settings.submit_btn_text" placeholder="ex: Submit Program" rules="required" />
                                                        </div>
                                                        <ErrorMessage name="name" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Button Color <a class="reset-default" @click="form.public_settings.submit_btn_bgcolor = defaultPlanForm.public_settings.submit_btn_bgcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="button_bg_color" type="text" label="button background color">
                                                            <color-picker v-model="form.public_settings.submit_btn_bgcolor" />
                                                        </Field>
                                                        <ErrorMessage name="button_bg_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Text Color <a class="reset-default" @click="form.public_settings.submit_btn_textcolor = defaultPlanForm.public_settings.submit_btn_textcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="button_text_color" type="text" label="button text color">
                                                            <color-picker v-model="form.public_settings.submit_btn_textcolor" />
                                                        </Field>
                                                        <ErrorMessage name="button_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="ftrsetting">
                                <div class="section_header">
                                    <h2>Footer</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_footer ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_footer ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1 mb-2">
                                        <label for="has-login-footer" class="switch_option capsule_btn">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="has-login-footer" :true-value="1" :false-value="0" v-model="form.public_settings.display_footer" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <Transition duration="550" name="nested">
                                            <div class="mt-4 outer" v-show="form.public_settings.display_footer">
                                                <label for="facebook" class="switch_option capsule_btn p-0 border-0">
                                                    <h5 class="large">Facebook</h5>
                                                    <input type="checkbox" id="facebook" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_facebook" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_facebook">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_facebook" v-model="form.public_settings.footer_facebook" placeholder="Facebook link here" rules="url|validate_url:facebook" label="facebook" />
                                                            </div>
                                                            <ErrorMessage name="footer_facebook" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="twitter" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                    <h5 class="large">Twitter</h5>
                                                    <input type="checkbox" id="twitter" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_twitter" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_twitter">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_twitter" v-model="form.public_settings.footer_twitter" placeholder="Twitter link here" rules="url|validate_url:twitter" label="twitter" />
                                                            </div>
                                                            <ErrorMessage name="footer_twitter" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="instagram" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                    <h5 class="large">Instagram</h5>
                                                    <input type="checkbox" id="instagram" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_instagram" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_instagram">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_instagram" v-model="form.public_settings.footer_instagram" placeholder="Instagram link here" rules="url|validate_url:instagram" label="instagram" />
                                                            </div>
                                                            <ErrorMessage name="footer_instagram" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="linkedin" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                    <h5 class="large">Linkedin</h5>
                                                    <input type="checkbox" id="linkedin" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_linkedin" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_linkedin">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_linkedin" v-model="form.public_settings.footer_linkedin" placeholder="Linkedin link here" rules="url|validate_url:linkedin" label="linkedin" />
                                                            </div>
                                                            <ErrorMessage name="footer_linkedin" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="youtube" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                    <h5 class="large">Youtube</h5>
                                                    <input type="checkbox" id="youtube" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_youtube" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_youtube">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_youtube" v-model="form.public_settings.footer_youtube" placeholder="Youtube link here" rules="url|validate_url:youtu" label="youtube" />
                                                            </div>
                                                            <ErrorMessage name="footer_youtube" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr">
                                                    <div class="group_item mt-3">
                                                        <label class="input_label">Icons Color <a class="reset-default" @click="form.public_settings.footer_textcolor = defaultPlanForm.public_settings.footer_textcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="footer_textcolor" v-model="form.public_settings.footer_textcolor" type="text" label="element color">
                                                            <color-picker v-model="form.public_settings.footer_textcolor"/>
                                                        </Field>
                                                        <ErrorMessage name="footer_textcolor" class="text-danger" />
                                                    </div>
                                                    <div class="group_item mt-3">
                                                        <label class="input_label">Background Color <a class="reset-default" @click="form.public_settings.footer_bgcolor = defaultPlanForm.public_settings.footer_bgcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="footer_bgcolor" v-model="form.public_settings.footer_bgcolor" type="text" label="element color">
                                                            <color-picker v-model="form.public_settings.footer_bgcolor"/>
                                                        </Field>
                                                        <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                    <div class="color_container mt-2 mb-2" v-show="form.public_settings.display_footer">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Copyright Text</label>
                                                    <div class="field_wpr m-0">
                                                        <Field autocomplete="off" type="text" name="footer_company" v-model="form.public_settings.footer_company" :placeholder="`© ${new Date().getFullYear()} - ${user.businessname ? user.businessname : 'Company Name'}`" />
                                                    </div>
                                                    <ErrorMessage name="footer_company" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Terms Link</label>
                                                    <div class="field_wpr has_prefix m-0">
                                                        <Field autocomplete="off" type="text" name="footer_terms" v-model="form.public_settings.footer_terms" placeholder="https://superfitcoaching.com" rules="url" label="term link" />
                                                        <span class="prefix">URL</span>
                                                    </div>
                                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                    <ErrorMessage name="footer_terms" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Privacy Link</label>
                                                    <div class="field_wpr has_prefix m-0">
                                                        <Field autocomplete="off" type="text" name="footer_policy" v-model="form.public_settings.footer_policy" placeholder="https://superfitcoaching.com" rules="url" label="privacy link" />
                                                        <span class="prefix">URL</span>
                                                    </div>
                                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                    <ErrorMessage name="footer_policy" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="planStep == 3">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Notifications</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <swiper :slidesPerView="'auto'" :spaceBetween="0" :navigation='true' class="tab_slider">
                                            <swiper-slide class="mr-3"><span @click="reminderTab = 'reminders'" :class="{ active: reminderTab === 'reminders' }">Delivery Message <label class="tag" :style="`background: #${form.reminder.has_reminder ? '2f7eed' : 'f2a31d'}`">{{ form.reminder.has_reminder ? 'On' : 'Off' }}</label></span></swiper-slide>
                                            <swiper-slide class="mr-3"><span @click="reminderTab = 'admin'" :class="{ active: reminderTab === 'admin' }">Admin Notiication <label class="tag" :style="`background: #${form.reminder.has_notification ? '2f7eed' : 'f2a31d'}`">{{ form.reminder.has_notification ? 'On' : 'Off' }}</label></span></swiper-slide>
                                            <swiper-slide><span @click="reminderTab = 'award'" :class="{ active: reminderTab === 'award' }">Award Points <label class="tag" :style="`background: #${form.rewards_settings.has_reward_points ? '2f7eed' : 'f2a31d'}`">{{ form.rewards_settings.has_reward_points ? 'On' : 'Off' }}</label></span></swiper-slide>
                                        </swiper>
                                        <Transition duration="550" name="nested">
                                            <div class="outer" v-if="reminderTab === 'reminders'">
                                                <label for="track_reminders" class="switch_option capsule_btn mt-2">
                                                    <h5 class="large">Reminders</h5>
                                                    <input type="checkbox" id="track_reminders" :true-value="1" :false-value="0" v-model="form.reminder.has_reminder" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.reminder.has_reminder" class="mt-2 outer">
                                                        <div class="edit_section mb-2">
                                                            <sending-method v-model="form.reminder.sending_method" />
                                                        </div>
                                                        <div v-if="form.reminder.sending_method == 1 || form.reminder.sending_method == 3">
                                                            <email-component v-model="reminderEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleReminderDefaultEmail" preview-module="plan" ref="reminder-email-component" is-full-view />
                                                        </div>
                                                        <div v-if="form.reminder.sending_method == 2 || form.reminder.sending_method == 3" class="sms-component">
                                                            <sms-component v-model="form.reminder.sms_message" media-field-name="reminder_sms_media" :media="form.reminder.media" :default-sms-handler="true" preview-module="plan" :handle-default-sms="handleReminderDefaultSms" :update-sms-text="updateSmsText()" ref="message-sms-component" />
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                        <Transition duration="550" name="nested">
                                            <div class="outer" v-if="reminderTab === 'admin'">
                                                <label for="adm_notification" class="switch_option capsule_btn mt-2">
                                                    <h5 class="large">Admin Notification</h5>
                                                    <input type="checkbox" id="adm_notification" :true-value="1" :false-value="0" v-model="form.reminder.has_notification" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.reminder.has_notification" class="mt-2 outer">
                                                        <ul class="tab_sec mb-4">
                                                            <li @click="adminNotificationTab = 'started'" :class="{ active: adminNotificationTab === 'started' }">Started Program</li>
                                                            <li @click="adminNotificationTab = 'completed'" :class="{ active: adminNotificationTab === 'completed' }">Completed Program</li>
                                                        </ul>
                                                        <div v-if="adminNotificationTab === 'started'">
                                                            <div class="edit_section mb-2">
                                                                <sending-method v-model="form.reminder.notification_sending_method" />
                                                            </div>
                                                            <div v-if="form.reminder.notification_sending_method == 1 || form.reminder.notification_sending_method == 3">
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Email Recipient</label>
                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.notification_email_recipient }">
                                                                            <Field autocomplete="off" name="notification_email_recipient" v-model="form.reminder.notification_email_recipient" rules="required" label="email recipient">
                                                                                <vue-tags-input :tags="form.reminder.notification_email_recipient" @on-tags-changed="handleNotificationEmailRecipient" :add-tag-on-blur="true" />
                                                                            </Field>
                                                                        </div>
                                                                        <ErrorMessage name="notification_email_recipient" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <email-component v-model="notificationEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleNotificationDefaultEmail" preview-module="plan" ref="notification-email-component" is-full-view />
                                                            </div>
                                                            <div v-if="form.reminder.notification_sending_method == 2 || form.reminder.notification_sending_method == 3" class="sms-component">
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">SMS Recipient</label>
                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.notification_sms_recipient }">
                                                                            <Field autocomplete="off" name="notification_sms_recipient" v-model="form.reminder.notification_sms_recipient" rules="required" label="sms recipient">
                                                                                <vue-tags-input :tags="form.reminder.notification_sms_recipient" @on-tags-changed="handleNotificationSMSRecipient" :add-tag-on-blur="true" />
                                                                            </Field>
                                                                        </div>
                                                                        <ErrorMessage name="notification_sms_recipient" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <sms-component v-model="form.reminder.notification_sms_message" media-field-name="notification_sms_media" :media="form.reminder.notification_media" :default-sms-handler="true" preview-module="plan" :handle-default-sms="handleNotificationDefaultSms" :update-sms-text="updateSmsText()" ref="notification-sms-component" />
                                                            </div>
                                                        </div>
                                                        <div v-if="adminNotificationTab === 'completed'">
                                                            <div class="edit_section mb-2">
                                                                <sending-method v-model="form.reminder.completed_notification_sending_method" />
                                                            </div>
                                                            <div v-if="form.reminder.completed_notification_sending_method == 1 || form.reminder.completed_notification_sending_method == 3">
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Email Recipient</label>
                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.completed_notification_email_recipient }">
                                                                            <Field autocomplete="off" name="completed_notification_email_recipient" v-model="form.reminder.completed_notification_email_recipient" rules="required" label="email recipient">
                                                                                <vue-tags-input :tags="form.reminder.completed_notification_email_recipient" @on-tags-changed="handleCompletedNotificationEmailRecipient" />
                                                                            </Field>
                                                                        </div>
                                                                        <ErrorMessage name="completed_notification_email_recipient" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <email-component v-model="completedNotificationEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleCompletedNotificationDefaultEmail" preview-module="plan" ref="completed-notification-email-component" is-full-view />
                                                            </div>
                                                            <div v-if="form.reminder.completed_notification_sending_method == 2 || form.reminder.completed_notification_sending_method == 3" class="sms-component">
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">SMS Recipient</label>
                                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.completed_notification_sms_recipient }">
                                                                            <Field autocomplete="off" name="completed_notification_sms_recipient" v-model="form.reminder.completed_notification_sms_recipient" rules="required" label="sms recipient">
                                                                                <vue-tags-input :tags="form.reminder.completed_notification_sms_recipient" @on-tags-changed="handleCompletedNotificationSMSRecipient" />
                                                                            </Field>
                                                                        </div>
                                                                        <ErrorMessage name="completed_notification_sms_recipient" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <sms-component v-model="form.reminder.completed_notification_sms_message" media-field-name="completed_notification_sms_media" :media="form.reminder.completed_notification_media" :default-sms-handler="true" preview-module="plan" :handle-default-sms="handleCompletedNotificationDefaultSms" :update-sms-text="updateSmsText()" ref="completed-notification-sms-component" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                        <Transition duration="550" name="nested">
                                            <div class="outer" v-if="reminderTab === 'award'">
                                                <h3 class="text-center">Coming Soon</h3>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="planStep == 4">
                            <div class="section_content w-100 open_area" @click="goTo('seosection')" ref="seosetting">
                                <div class="section_header">
                                    <h2>SEO Settings</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_title }">
                                                    <Field autocomplete="off" type="text" name="seo_title" placeholder="ex: The title will be displayed in browser tabs." v-model="form.public_settings.seo_title" />
                                                </div>
                                                <ErrorMessage name="seo_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Keywords</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_keyword }">
                                                    <Field autocomplete="off" type="text" name="seo_keyword" placeholder="ex: Several keywords that describe your page best." v-model="form.public_settings.seo_keyword" />
                                                </div>
                                                <ErrorMessage name="seo_keyword" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.seo_desc }">
                                                    <Field autocomplete="off" type="textarea" name="seo_desc" v-model="form.seo_desc">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.public_settings.seo_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="seo_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <label for="page_indexed" class="switch_option capsule_btn border-0">
                                        <h5 class="large">I want my page indexed by search engine</h5>
                                        <input type="checkbox" id="page_indexed" :true-value="1" :false-value="0" v-model="form.public_settings.seo_index" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="section_content w-100 open_area" @click="goTo('sharesection')" ref="sharesetting">
                                <div class="section_header">
                                    <h2>Social Share</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_share_title }">
                                                    <Field autocomplete="off" type="text" name="seo_share_title" placeholder="ex: New Title" v-model="form.public_settings.seo_share_title" />
                                                </div>
                                                <ErrorMessage name="seo_share_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.seo_share_desc }">
                                                    <Field autocomplete="off" type="textarea" name="seo_share_desc" v-model="form.public_settings.seo_share_desc">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.public_settings.seo_share_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="seo_share_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="sub_header mt-4">SEO Thumbnail</h3>
                                    <image-library v-model="form.public_settings.seo_thumb" image-type="social-share" />
                                </div>
                            </div>
                            <div class="section_content w-100 open_area" @click="goTo('favsection')" ref="favsetting">
                                <div class="section_header">
                                    <h2>Favicon</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <image-library v-model="form.public_settings.seo_fav" image-type="favicon" />
                                </div>
                            </div>
                        </div>

                        <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                    </div>
                    <div class="action_wpr">
                        <button type="button" class="btn cancel_btn" @click="closeModal()" v-if="planStep == 1">Close</button>
                        <button type="button" class="btn cancel_btn" @click="planStep -= 1" v-if="planStep > 1">Back</button>
                        <button type="button" class="btn danger_btn" v-if="workoutPlan.id" @click="handleDeletePlan([workoutPlan.id])">Delete</button>
                        <button class="btn save_btn" id="plan-save-button" v-if="planStep < 4"><i class="fa fa-spinner fa-spin" v-if="workoutPlanUpdateLoader"></i> {{ workoutPlanUpdateLoader ? 'Saving' : 'Next' }}</button>
                        <button class="btn save_btn" id="plan-save-button" v-if="planStep == 4"><i class="fa fa-spinner fa-spin" v-if="workoutPlanUpdateLoader"></i> {{ workoutPlanUpdateLoader ? (isNew ? 'Saving' : 'Updating') : (isNew ? 'Finish' : 'Update') }}</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="planStep == 1 || planStep == 2">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="full_preview" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="large">Full View</h5>
                            <input type="checkbox" id="full_preview" v-model="fullPreview" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <workout-public-preview :workout-plan="form" :workout-weeks="workoutPlanWeeks" :public-settings="form.public_settings" :is-plan="true" />
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="planStep == 3">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_notification" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_notification" v-model="previewReminder" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="reminderPreviewType = 'email'" :class="{'active' : reminderPreviewType == 'email'}" v-if="reminderTab == 'reminders' && (form.reminder.sending_method == 1 || form.reminder.sending_method == 3)">E-mail</li>
                    <li @click="reminderPreviewType = 'sms'" :class="{'active' : reminderPreviewType == 'sms'}" v-if="reminderTab == 'reminders' && (form.reminder.sending_method == 2 || form.reminder.sending_method == 3)">SMS</li>
                    <li @click="reminderPreviewType = 'email'" :class="{'active' : reminderPreviewType == 'email'}" v-if="reminderTab == 'admin' && (form.reminder.notification_sending_method == 1 || form.reminder.notification_sending_method == 3)">E-mail</li>
                    <li @click="reminderPreviewType = 'sms'" :class="{'active' : reminderPreviewType == 'sms'}" v-if="reminderTab == 'admin' && (form.reminder.notification_sending_method == 2 || form.reminder.notification_sending_method == 3)">SMS</li>
                </ul>
                <div class="cell">
                    <div class="content_area" v-if="reminderPreviewType == 'email'">
                        <div class="msg_preview" v-if="reminderTab == 'reminders'">
                            <h2>{{ replaceVariables(reminderEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="reminderEmail.show_email_logo">
                                <img :src="reminderEmail.email_logo ? reminderEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(reminderEmail.email, reminderEmail.email_button_bg_color, reminderEmail.email_button_text_color, 'View Program')"></div>
                            <div v-html="generateSignature(reminderEmail.is_signature, reminderEmail.signature_id)"></div>
                        </div>
                        <div class="msg_preview" v-if="reminderTab == 'admin' && adminNotificationTab == 'started'">
                            <h2>{{ replaceVariables(notificationEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="notificationEmail.show_email_logo">
                                <img :src="notificationEmail.email_logo ? notificationEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(notificationEmail.email, notificationEmail.email_button_bg_color, notificationEmail.email_button_text_color, 'View Analytics')"></div>
                            <div v-html="generateSignature(notificationEmail.is_signature, notificationEmail.signature_id)"></div>
                        </div>
                        <div class="msg_preview" v-if="reminderTab == 'admin' && adminNotificationTab == 'completed'">
                            <h2>{{ replaceVariables(completedNotificationEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="completedNotificationEmail.show_email_logo">
                                <img :src="completedNotificationEmail.email_logo ? completedNotificationEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(completedNotificationEmail.email, completedNotificationEmail.email_button_bg_color, completedNotificationEmail.email_button_text_color, 'View Feedback')"></div>
                            <div v-html="generateSignature(completedNotificationEmail.is_signature, completedNotificationEmail.signature_id)"></div>
                        </div>
                        <div class="msg_preview" v-if="reminderTab == 'award'">
                            <h2>{{ replaceVariables(rewardDetail.awarded_subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="rewardDetail.reward_show_email_logo">
                                <img :src="rewardDetail.reward_email_logo ? rewardDetail.reward_email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(rewardDetail.awarded_email, rewardDetail.email_button_bg_color, rewardDetail.email_button_text_color, 'View Program')"></div>
                            <div v-html="generateSignature(rewardDetail.is_reward_signature, rewardDetail.reward_signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mt-2 mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="reminderPreviewType == 'sms'">
                        <div class="sms_preview">
                            <div v-if="reminderTab == 'reminders'" class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(messageSmsText, '', '', '', '', false)"></div>
                            <div v-if="reminderTab == 'admin' && adminNotificationTab == 'started'" class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(notificationSmsText, '', '', '', '', false)"></div>
                            <div v-if="reminderTab == 'admin' && adminNotificationTab == 'completed'" class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(completedNotificationSmsText, '', '', '', '', false)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="planStep == 4">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="area_wpr">
                    <h3 class="sub_header" ref="seosection" @click="setting('seosetting')">Search Engine Listing</h3>
                    <div class="google_ranking">
                        <h6>About 5,29,000 results(0.36 seconds)</h6>
                        <h4>{{ form.public_settings.seo_title ? replaceVariables(form.public_settings.seo_title) : 'Lorem Ipsum Dolor' }}</h4>
                        <p>{{ form.public_settings.seo_desc ? form.public_settings.seo_desc : 'It takes whole chocolate protein. The members of the fleet are turpis at the ends of the pulvinar. No vehicles are accepted at the bed.' }}</p>
                    </div>
                </div>
                <div class="area_wpr" ref="sharesection" @click="setting('sharesetting')">
                    <h3 class="sub_header">Social Share</h3>
                    <div class="seo_card">
                        <img :src="form.public_settings.seo_thumb ? form.public_settings.seo_thumb : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                        <div class="seo_info">
                            <h3>{{ form.public_settings.seo_share_title ? replaceVariables(form.public_settings.seo_share_title) : 'Optimize Your Metabolism In 30 Days' }}</h3>
                            <p>{{ form.public_settings.seo_share_desc ? form.public_settings.seo_share_desc : 'It is easy to speed upa slow metabolism in as little as 30 days using this proven technique.' }}</p>
                        </div>
                    </div>
                </div>
                <div class="area_wpr" ref="favsection" @click="setting('favsetting')">
                    <h3 class="sub_header">Favicon</h3>
                    <div class="browser_layout">
                        <ul class="browser_tabs">
                            <li>
                                <img :src="form.public_settings.seo_fav ? form.public_settings.seo_fav : (env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo.svg') : require('@/assets/images/logo.png'))" alt="">
                                <h6>{{ form.public_settings.seo_title ? (form.public_settings.seo_title.length > 15 ? replaceVariables(form.public_settings.seo_title).substr(0, 15)+'...' : form.public_settings.seo_title) : 'Thrive Coach' }}</h6>
                                <i class="fas fa-times"></i>
                            </li>
                            <li><i class="fas fa-plus"></i></li>
                        </ul>
                        <div class="browser_info">
                            <i class="fas fa-arrow-left"></i>
                            <i class="fas fa-arrow-right"></i>
                            <i class="fas fa-redo"></i>
                            <div class="browser_search">
                                <i class="fas fa-lock"></i>
                                <p>https://thrivecoach.io</p>
                                <i class="far fa-star ml-auto"></i>
                            </div>
                            <img src="@/assets/images/default-avatar.svg" class="user" alt=""/>
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <preview-plan v-model="fullPreview" :plan="form" :preview-page="previewPage" />
        <reminder-preview v-model="previewReminder" :reminder-tab="reminderTab" client-reminder-tab="client" :form="form" :reminder-email="reminderEmail"  :notification-email="adminNotificationTab == 'completed' ? completedNotificationEmail : notificationEmail" />
        <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
    </div>
</template>

<script>
    import { copyText } from 'vue3-clipboard'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
    import SwiperCore, { Navigation } from 'swiper'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { defineAsyncComponent } from 'vue'

    const ReminderPreview = defineAsyncComponent(() => import('@/pages/workout/components/ReminderPreview'))
    const WorkoutPublicPreview = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutPublicPreview'))
    
    const PreviewPlan = defineAsyncComponent(() => import('@/pages/workout/components/plan/PreviewPlan'))
    const PlanWeekSetting = defineAsyncComponent(() => import('@/pages/workout/components/plan/PlanWeekSetting'))

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import VueTagsInput from 'vue3-tags-input'
    import 'swiper/swiper-bundle.min.css'

    SwiperCore.use([Navigation])

    export default {
        name: 'Plan Wizard',

        data () {
            return  {
                planStep: 1,
                isNew: false,
                toggleAll: 1,
                editWorkoutPlanName: false,
                showDescription: false,
                tutorial: false,
                tutorialVideo: '',
                form: {
                    name: "",
                    access_code: "",
                    url: "",
                    has_optin_phone: 0,
                    reminder: {
                        has_reminder: 1,
                        sending_method: 1,
                        throttle_for_user_tz: 1,
                        recipient_email:'',
                        recipient_mobile:'',
                        email_subject: '',
                        email_message: '',
                        sms_message: '',
                        media: [],
                        show_email_logo: true,
                        show_email_signature: false,
                        signature_id: 0,
                        logo_image: '',
                        has_notification: 0,
                        notification_sending_method: 1,
                        notification_email_subject: '',
                        notification_email_message: '',
                        notification_sms_message: '',
                        show_notification_email_logo: true,
                        notification_logo_image: '',
                        notification_media: [],
                        notification_email_recipient: [this.user ? this.user.email : ''],
                        notification_sms_recipient: [this.user ? this.user.sms_notification_number : ''],
                        show_signature: 0,
                        show_notification_signature: 0,
                        notification_signature_id: 0,
                        email_button_bg_color: '',
                        email_button_text_color: '',
                        notification_email_button_bg_color: '',
                        notification_email_button_text_color: '',
                    },
                    rewards_settings: {
                        has_reward_points: 0,
                        reward_points: 0,
                        reward_notif: 0,
                        reward_notif_method: 1,
                        reward_reason: 'submitting your workout plan report',
                    },
                    public_settings: {
                        workout_type: 'single',
                        display_explainer_video: 0,
                        explainer_video_type: 0,
                        explainer_video: "",
                        explainer_embed: "",
                        display_company_branding: 1,
                        company_branding: "",
                        display_logo:1,
                        logo: '',
                        display_author:1,
                        author:"",
                        display_header:1,
                        display_footer:1,
                        header_bgcolor: '#FFF',
                        header_textcolor: '#000',
                        submit_btn_text: 'Submit Program',
                        element_color: '#2f7eed',
                        element_text_color: '#2C3E50',
                        block_bg_color: '#eff5ff',
                        timer_color: '#f2a31d',
                        submit_btn_textcolor: '#fff',
                        submit_btn_bgcolor: '#2c3e50',
                        footer_bgcolor: '#D4D4D4',
                        footer_textcolor: '#2C3E50',
                        footer_has_facebook: 1,
                        footer_has_twitter: 1,
                        footer_has_instagram: 1,
                        footer_has_linkedin: 0,
                        footer_has_youtube: 0,
                        thankyou_bgcolor: '#edebeb',
                        thankyou_textcolor: '#2c3e50',
                    },
                    categories: [],
                },
                months: {
                    1: 'Jan',
                    2: 'Feb',
                    3: 'Mar',
                    4: 'Apr',
                    5: 'May',
                    6: 'Jun',
                    7: 'Jul',
                    8: 'Aug',
                    9: 'Sep',
                    10: 'Oct',
                    11: 'Nov',
                    12: 'Dec'
                },
                env: {},
                reminderTab: 'reminders',
                preview: false,
                reminderEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                notificationEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                completedNotificationEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                reminderPreviewType: 'email',
                previewReminder: false,
                headerTab: 'logo',
                fullPreview: false,
                messageSmsText: '',
                notificationSmsText: '',
                completedNotificationSmsText: '',
                reminder_sms_media: [],
                notification_sms_media: [],
                previewPage: 'plan',
                switchStep: true,
                dummyFile: [],
                lastStep: 0,
                adminNotificationTab: 'started',
                loader: false,
                createCategory: false,
                categoryForm: {
                    name: '',
                    is_workout_plan: 1,
                },
            };
        },

        props: {
            modelValue: Boolean,
            refreshWorkoutPlan: {
                type: Function,
                default: () => {}
            }
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            VueTagsInput,
            PreviewPlan,
            ReminderPreview,
            Swiper,
            SwiperSlide,
            PlanWeekSetting,
            WorkoutPublicPreview,
        },

        watch: {
            modelValue (val) {
                const vm = this;

                vm.toggleScrollBar(val);

                if (val) {
                    vm.previewPage = 'plan';
                    vm.activePlan  = 0;
                    vm.switchStep  = true;
                    vm.resetForm();

                    if (vm.planStep == 1) {
                        vm.preview = false;
                    }

                    if (vm.rewardDetail && !Object.keys(vm.rewardDetail).length) {
                        vm.getReward();
                    }

                    vm.getWorkoutCategories({ is_workout_plan: 1 });
                }

                vm.editWorkoutPlanName = false;
            },

            workoutPlan (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm(false);
                }
            },

            fullPreview (val) {
                const vm = this;

                if (val) {
                    document.body.classList.add('modal-open');

                    const button = document.getElementById('plan-save-button');

                    if (button) {
                        vm.switchStep   = false;
                        vm.closeWindow  = false;
                        button.click();
                    }
                }

                vm.toggleGistChatBox();
            },

            'form.name' (name) {
                const vm = this;

                vm.addVariableToReservedWords({ name: 'workout_plan_name', value: name });
                vm.addVariableToReservedWords({ name: 'plan_name', value: name });
            },

            'form.reminder.sending_method': {
                handler (val) {
                    const vm = this;

                    vm.reminderTab         = 'reminders';
                    vm.reminderPreviewType = val == 2 ? 'sms' : 'email';
                },
                deep: true,
            },

            'form.reminder.notification_sending_method': {
                handler (val) {
                    const vm = this;

                    vm.reminderTab         = 'admin';
                    vm.reminderPreviewType = val == 2 ? 'sms' : 'email';
                },
                deep: true,
            },

            reminderTab (tab) {
                const vm = this;

                if (tab == 'reminders') {
                    vm.reminderPreviewType = vm.form.reminder.sending_method == 2 ? 'sms' : 'email';
                    vm.messageSmsText      = vm.form.reminder && vm.form.reminder.media ? vm.form.reminder.sms_message + '\n\n' + vm.form.reminder.media : '';
                } else if (tab == 'admin') {
                    vm.reminderPreviewType          = vm.form.reminder.notification_sending_method == 2 ? 'sms' : 'email';
                    vm.notificationSmsText          = vm.form.reminder && vm.form.reminder.notification_media ? vm.form.reminder.notification_sms_message + '\n\n' + vm.form.reminder.notification_media : '';
                    vm.completedNotificationSmsText = vm.form.reminder && vm.form.reminder.completed_notification_media ? vm.form.reminder.completed_notification_sms_message + '\n\n' + vm.form.reminder.completed_notification_media : '';
                }
            },

            reminder_sms_media (media) {
                const vm = this;

                vm.form.reminder.media = media;
            },

            notification_sms_media (media) {
                const vm = this;

                vm.form.reminder.notification_media = media;
            },

            planStep (val) {
                const vm = this;

                vm.toggleAll = 1;

                if (val == 1) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            workoutPlanUpdateLoader (status) {
                const vm = this;

                if (!status) {
                    vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id});
                }
            }
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                timezones: state => state.commonModule.timezones,
                workoutPlan: state => state.workoutModule.selectedWorkoutPlan,
                rewardDetail: state => state.rewardModule.reward,
                defaultPlanForm: state => state.workoutModule.defaultPlanForm,
                workoutPlanUpdateLoader: state => state.workoutModule.workoutPlanUpdateLoader,
                workoutPlanWeekLoader: state => state.workoutModule.workoutPlanWeekLoader,
                workoutPlanWeeks: state => state.workoutModule.workoutPlanWeeks,
                categorySaveLoader: state => state.workoutModule.categorySaveLoader,
                workoutCategories: state => state.workoutModule.workoutCategories,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            if (!vm.timezones.length) {
                vm.getTimezones();
            }

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
            vm.env = process.env;

            if (vm.planStep == 1) {
                vm.preview = false;
            }

            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler(null, true);
        },

        unmounted () {
            const vm = this;

            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                getTimezones: 'commonModule/getTimezones',
                saveWorkoutPlan: 'workoutModule/saveWorkoutPlan',
                deleteWorkoutPlan: 'workoutModule/deleteWorkoutPlan',
                createPlanBlankWeek: 'workoutModule/createPlanBlankWeek',
                getReward: 'rewardModule/getReward',
                renameWorkoutPlan: 'workoutModule/renameWorkoutPlan',
                getWorkoutPlanWeeks: 'workoutModule/getWorkoutPlanWeeks',
                getWorkoutCategories: 'workoutModule/getWorkoutCategories',
                createWorkoutCategory: 'workoutModule/createWorkoutCategory',
            }),

            ...mapMutations({
                addVariableToReservedWords: 'commonModule/ADD_VARIABLE_TO_RESERVED_WORDS',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);

                if (vm.$parent.$parent && vm.$parent.$parent.getWorkoutPlans) {
                    vm.$parent.$parent.getWorkoutPlans(vm.$parent.$parent.params);
                } else {
                    vm.$parent.getWorkoutPlans(vm.$parent.params);
                }
            },

            toggleStep (step) {
                const vm = this;

                if (vm.isNew) {
                    if (step <= vm.lastStep) {
                        vm.planStep = step;
                    }
                } else {
                    vm.planStep = step;
                }
            },

            resetForm (force = true) {
                const vm = this;

                if (force) {
                    vm.planStep  = 1;
                    vm.headerTab = 'logo';

                    setTimeout(function () {
                        vm.reminderTab = 'reminders';
                    }, 2000);

                    if (vm.workoutPlan.id) {
                        vm.isNew = false;
                    }
                }

                if (vm.workoutPlan.id) {
                    setTimeout(function () {
                        const plan = JSON.parse(JSON.stringify(vm.workoutPlan));

                        vm.form = plan;
                        vm.form.reminder.notification_sending_method = parseInt(vm.form.reminder.notification_sending_method);
                        vm.form.categories = vm.workoutPlan.categories && vm.workoutPlan.categories.length ? vm.workoutPlan.categories.map(category => category.category_id) : [];

                        if (vm.form.public_settings && vm.form.public_settings.explainer_video_type === undefined) {
                            vm.form.public_settings.explainer_video_type  = 0;
                            vm.form.public_settings.explainer_embed = '';
                        }

                        if (vm.form.reminder.notification_sending_method == undefined) {
                            vm.form.reminder.has_notification             = 0;
                            vm.form.reminder.notification_sending_method  = 1;
                            vm.form.reminder.notification_email_subject   = vm.getDefaultMessage('email-subject', 'admin-notification-started');
                            vm.form.reminder.notification_email_message   = vm.getDefaultMessage('email-content', 'admin-notification-started');
                            vm.form.reminder.notification_sms_message     = vm.getDefaultMessage('sms-content', 'admin-notification-started');
                            vm.form.reminder.show_notification_email_logo = true;
                            vm.form.reminder.notification_logo_image      = '';
                            vm.form.reminder.notification_media           = [];
                            vm.form.reminder.notification_email_recipient = [vm.user.email];
                            vm.form.reminder.notification_sms_recipient   = [vm.user.sms_notification_number];
                        } else if (typeof vm.form.reminder.notification_email_recipient == 'string') {
                            vm.form.reminder.notification_email_recipient = [vm.user.email];
                            vm.form.reminder.notification_sms_recipient   = [vm.user.sms_notification_number];
                        }

                        if (vm.form.reminder.completed_notification_sending_method == undefined) {
                            vm.form.reminder.completed_notification_sending_method  = 1;
                            vm.form.reminder.completed_notification_email_subject   = vm.getDefaultMessage('email-subject', 'admin-notification-started');
                            vm.form.reminder.completed_notification_email_message   = vm.getDefaultMessage('email-content', 'admin-notification-started');
                            vm.form.reminder.completed_notification_sms_message     = vm.getDefaultMessage('sms-content', 'admin-notification-started');
                            vm.form.reminder.show_completed_notification_email_logo = true;
                            vm.form.reminder.completed_notification_logo_image      = '';
                            vm.form.reminder.completed_notification_media           = [];
                            vm.form.reminder.completed_notification_email_recipient = [vm.user.email];
                            vm.form.reminder.completed_notification_sms_recipient   = [vm.user.sms_notification_number];
                        } else if (typeof vm.form.reminder.notification_email_recipient == 'string') {
                            vm.form.reminder.completed_notification_email_recipient = [vm.user.email];
                            vm.form.reminder.completed_notification_sms_recipient   = [vm.user.sms_notification_number];
                        }

                        if (vm.form.reminder.show_notification_signature == undefined) {
                            vm.form.reminder.show_notification_signature = 0;
                            vm.form.reminder.notification_signature_id = 0;
                        }

                        if (vm.form.public_settings.footer_has_facebook == undefined) {
                            vm.form.public_settings.footer_has_facebook = 1;
                            vm.form.public_settings.footer_has_twitter = 1;
                            vm.form.public_settings.footer_has_instagram = 1;
                            vm.form.public_settings.footer_has_linkedin = 1;
                            vm.form.public_settings.footer_has_youtube = 1;
                        }

                        if (vm.form.public_settings.seo_title == undefined) {
                            vm.form.public_settings.seo_title = '';
                            vm.form.public_settings.seo_keyword = '';
                            vm.form.public_settings.seo_desc = '';
                            vm.form.public_settings.seo_index = 0;
                            vm.form.public_settings.seo_share_title = '';
                            vm.form.public_settings.seo_share_desc = '';
                            vm.form.public_settings.seo_thumb = '';
                            vm.form.public_settings.seo_fav = '';
                        }

                        if (vm.form.public_settings.thankyou_bgcolor == undefined) {
                            vm.form.public_settings.thankyou_bgcolor = '#edebeb';
                            vm.form.public_settings.thankyou_textcolor = '#2c3e50';
                        }

                        vm.reminderEmail = {
                            defaultHandler: true,
                            subject: vm.form.reminder.email_subject,
                            email: vm.form.reminder.email_message,
                            is_email_logo: vm.form.reminder.show_email_logo ? 1 : 0,
                            is_signature: vm.form.reminder.show_signature ? 1 : 0,
                            signature_id: vm.form.reminder.signature_id,
                            show_email_logo: vm.form.reminder.show_email_logo ? 1 : 0,
                            email_logo: vm.form.reminder.logo_image,
                            email_button_bg_color: vm.form.reminder.email_button_bg_color ? vm.form.reminder.email_button_bg_color : '#2c3e50',
                            email_button_text_color: vm.form.reminder.email_button_text_color ? vm.form.reminder.email_button_text_color : '#FFF',
                            module_id: vm.workoutPlan.id,
                        };

                        vm.notificationEmail = {
                            defaultHandler: true,
                            subject: vm.form.reminder.notification_email_subject,
                            email: vm.form.reminder.notification_email_message,
                            is_email_logo: vm.form.reminder.show_notification_email_logo ? 1 : 0,
                            is_signature: vm.form.reminder.notification_show_signature ? 1 : 0,
                            signature_id: vm.form.reminder.notification_signature_id,
                            show_email_logo: vm.form.reminder.show_notification_email_logo ? 1 : 0,
                            email_logo: vm.form.reminder.notification_logo_image,
                            email_button_bg_color: vm.form.reminder.notification_email_button_bg_color ? vm.form.reminder.notification_email_button_bg_color : '#2c3e50',
                            email_button_text_color: vm.form.reminder.notification_email_button_text_color ? vm.form.reminder.notification_email_button_text_color : '#FFF',
                            module_id: vm.workoutPlan.id,
                        };

                        vm.completedNotificationEmail = {
                            defaultHandler: true,
                            subject: vm.form.reminder.completed_notification_email_subject,
                            email: vm.form.reminder.completed_notification_email_message,
                            is_email_logo: vm.form.reminder.show_completed_notification_email_logo ? 1 : 0,
                            is_signature: vm.form.reminder.completed_notification_show_signature ? 1 : 0,
                            signature_id: vm.form.reminder.completed_notification_signature_id,
                            show_email_logo: vm.form.reminder.show_completed_notification_email_logo ? 1 : 0,
                            email_logo: vm.form.reminder.completed_notification_logo_image,
                            email_button_bg_color: vm.form.reminder.completed_notification_email_button_bg_color ? vm.form.reminder.completed_notification_email_button_bg_color : '#2c3e50',
                            email_button_text_color: vm.form.reminder.completed_notification_email_button_text_color ? vm.form.reminder.completed_notification_email_button_text_color : '#FFF',
                            module_id: vm.workoutPlan.id,
                        };

                        vm.messageSmsText               = vm.workoutPlan.reminder && vm.workoutPlan.reminder.sms_message ? vm.workoutPlan.reminder.sms_message : '';
                        vm.notificationSmsText          = vm.workoutPlan.reminder && vm.workoutPlan.reminder.notification_sms_message ? vm.workoutPlan.reminder.notification_sms_message : '';
                        vm.completedNotificationSmsText = vm.workoutPlan.reminder && vm.workoutPlan.reminder.completed_notification_sms_message ? vm.workoutPlan.reminder.completed_notification_sms_message : '';
                    }, 100);
                }

                vm.categoryForm = {
                    name: '',
                    is_workout_plan: 1,
                };

                vm.createCategory = false;

                setTimeout(function () {
                    vm.reminderTab = 'reminders';
                }, 500);
            },

            resetPlan () {
                const vm = this;

                vm.form = {
                    name: "",
                    access_code: "",
                    url: "",
                    has_optin_phone: 0,
                    reminder: {
                        has_reminder: 1,
                        sending_method: 1,
                        throttle_for_user_tz: 1,
                        recipient_email:'',
                        recipient_mobile:'',
                        email_subject: '',
                        email_message: '',
                        sms_message: '',
                        media: [],
                        show_email_logo: true,
                        show_email_signature: false,
                        signature_id: 0,
                        logo_image: '',
                        has_notification: 0,
                        notification_sending_method: 1,
                        notification_email_subject: '',
                        notification_email_message: '',
                        notification_sms_message: '',
                        show_notification_email_logo: true,
                        notification_logo_image: '',
                        notification_media: [],
                        notification_email_recipient: [vm.user ? vm.user.email : ''],
                        notification_sms_recipient: [vm.user ? vm.user.sms_notification_number : ''],
                        show_signature: 0,
                        show_notification_signature: 0,
                        notification_signature_id: 0,
                        email_button_bg_color: '#2c3e50',
                        email_button_text_color: '#FFF',
                        notification_email_button_bg_color: '#2c3e50',
                        notification_email_button_text_color: '#FFF',
                    },
                    rewards_settings: {
                        has_reward_points: 0,
                        reward_points: 0,
                        reward_notif: 0,
                        reward_notif_method: 1,
                        reward_reason: 'submitting your workout plan report',
                    },
                    public_settings: {
                        workout_type: 'single',
                        display_explainer_video: 0,
                        explainer_video_type: 0,
                        explainer_video: "",
                        explainer_embed: "",
                        display_company_branding: 1,
                        company_branding: "",
                        
                        display_logo:1,
                        logo: '',
                        display_author:1,
                        author:"",
                        display_header:1,
                        display_footer:1,
                        header_bgcolor: '#FFF',
                        header_textcolor: '#000',
                        submit_btn_text: 'Submit Program',
                        element_color: '#2f7eed',
                        element_text_color: '#2C3E50',
                        block_bg_color: '#eff5ff',
                        timer_color: '#f2a31d',
                        submit_btn_textcolor: '#fff',
                        submit_btn_bgcolor: '#2c3e50',
                        footer_bgcolor: '#D4D4D4',
                        footer_textcolor: '#2C3E50',
                        footer_has_facebook: 1,
                        footer_has_twitter: 1,
                        footer_has_instagram: 1,
                        footer_has_linkedin: 0,
                        footer_has_youtube: 0,
                        thankyou_bgcolor: '#edebeb',
                        thankyou_textcolor: '#2c3e50',
                        seo_title: '{{company_name}} Program',
                        seo_keyword: 'Plan',
                        seo_desc: `Whatever can be measured can be improved and what you focus on becomes reality. Let's track your plan!`,
                        seo_index: 0,
                        seo_share_title: '{{company_name}} Program',
                        seo_share_desc: `Whatever can be measured can be improved and what you focus on becomes reality. Let's track your plan!`,
                        seo_thumb: '',
                        seo_fav: '',
                    },
                };

                vm.generateDefaultMessage();

                vm.isNew = true;
                vm.headerTab = 'logo';

                vm.reminderEmail = {
                    defaultHandler: true,
                    subject: vm.form.reminder.email_subject,
                    email: vm.form.reminder.email_message,
                    is_email_logo: vm.form.reminder.show_email_logo,
                    is_signature: vm.form.reminder.show_signature,
                    signature_id: vm.form.reminder.signature_id,
                    show_email_logo: vm.form.reminder.show_email_logo,
                    email_logo: vm.form.reminder.logo_image,
                    email_button_bg_color: vm.form.reminder.email_button_bg_color ? vm.form.reminder.email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.form.reminder.email_button_text_color ? vm.form.reminder.email_button_text_color : '#FFF',
                };

                vm.notificationEmail = {
                    defaultHandler: true,
                    subject: vm.form.reminder.notification_email_subject,
                    email: vm.form.reminder.notification_email_message,
                    is_email_logo: vm.form.reminder.show_notification_email_logo,
                    is_signature: vm.form.reminder.notification_show_signature,
                    signature_id: vm.form.reminder.notification_signature_id,
                    show_email_logo: vm.form.reminder.show_notification_email_logo,
                    email_logo: vm.form.reminder.notification_logo_image,
                    email_button_bg_color: vm.form.reminder.notification_email_button_bg_color ? vm.form.reminder.notification_email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.form.reminder.notification_email_button_text_color ? vm.form.reminder.notification_email_button_text_color : '#FFF',
                };

                vm.completedNotificationEmail = {
                    defaultHandler: true,
                    subject: vm.form.reminder.completed_notification_email_subject,
                    email: vm.form.reminder.completed_notification_email_message,
                    is_email_logo: vm.form.reminder.show_completed_notification_email_logo,
                    is_signature: vm.form.reminder.completed_notification_show_signature,
                    signature_id: vm.form.reminder.completed_notification_signature_id,
                    show_email_logo: vm.form.reminder.show_completed_notification_email_logo,
                    email_logo: vm.form.reminder.completed_notification_logo_image,
                    email_button_bg_color: vm.form.reminder.completed_notification_email_button_bg_color ? vm.form.reminder.completed_notification_email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.form.reminder.completed_notification_email_button_text_color ? vm.form.reminder.completed_notification_email_button_text_color : '#FFF',
                };
            },

            generateDefaultMessage () {
                const vm = this;

                vm.form.reminder.email_subject  = '';
                vm.form.reminder.email_message  = '';
                vm.form.reminder.sms_message    = '';
                vm.form.reminder.notification_email_subject  = '';
                vm.form.reminder.notification_email_message  = '';
                vm.form.reminder.notification_sms_message    = '';
                vm.form.reminder.completed_notification_email_subject  = '';
                vm.form.reminder.completed_notification_email_message  = '';
                vm.form.reminder.completed_notification_sms_message    = '';

                vm.form.reminder.email_subject                          = vm.getDefaultMessage('email-subject', 'reminder');
                vm.form.reminder.email_message                          = vm.getDefaultMessage('email-content', 'reminder');
                vm.form.reminder.sms_message                            = vm.getDefaultMessage('sms-content', 'reminder');
                vm.form.reminder.notification_email_subject             = vm.getDefaultMessage('email-subject', 'admin-notification-started');
                vm.form.reminder.notification_email_message             = vm.getDefaultMessage('email-content', 'admin-notification-started');
                vm.form.reminder.notification_sms_message               = vm.getDefaultMessage('sms-content', 'admin-notification-started');
                vm.form.reminder.completed_notification_email_subject   = vm.getDefaultMessage('email-subject', 'admin-notification-completed');
                vm.form.reminder.completed_notification_email_message   = vm.getDefaultMessage('email-content', 'admin-notification-completed');
                vm.form.reminder.completed_notification_sms_message     = vm.getDefaultMessage('sms-content', 'admin-notification-completed');
            },

            getDefaultMessage (type, section) {
                if (type == 'email-subject' && section == 'reminder') {
                    return "🔥 [ENROLLED] Your new workout program {{ first_name }}";
                } else if (type == 'email-content' && section == 'reminder') {
                    let email_message = "<p>Hi {{first_name}},</p>";
                        email_message += "<p>{{company_owner_first_name}} from {{company_name}} has enrolled you in a new workout program.</p>";
                        email_message += "<p>Workout Program Name: {{workout_plan_name}}</p>";
                        email_message += "<p>Your program is also visible on your client portal calendar.</p><p></p>";
                        email_message += "<p>{{workout_plan_button}}</p>";
                        email_message += "<p></p><p>You can always chat with your coach via your client portal if you have any questions.</p>";
                        email_message += "<p>Cheers!</p><p></p>";
                        email_message += "<p>Team {{company_name}}</p>";

                    return email_message;
                } else if (type == 'sms-content' && section == 'reminder') {
                    let sms_message = "{{company_owner_first_name}} from {{company_name}} has enrolled you in a new workout program: {{workout_plan_name}}.\r\n";
                        sms_message += "Your program is also visible on your client portal calendar.\r\n";
                        sms_message += "{{workout_plan_link}}\r\n";
                        sms_message += "You can always chat with your coach via your client portal if you have any questions.\r\n";
                        sms_message += "Cheers!\r\n";
                        sms_message += "Team {{company_name}}";

                    return sms_message;
                } else if (type == 'email-subject' && section == 'admin-notification-started') {
                    return "Workout plan started by {{full_name}}.";
                } else if (type == 'email-content' && section == 'admin-notification-started') {
                    let email_message = "<p>{{full_name}} has started a workout plan.</p>";
                        email_message += "<p><b>Workout Plan Name:</b> {{workout_plan_name}}</p>";
                        email_message += "<p>Client email: {{email}}</p>";
                        email_message += "<p>You can always chat with your client via your message center or check their progress in your plan analytics.</p>";
                        email_message += "<p><br>{{plan_analytics_button}}</p>";
                        email_message += "<p>Cheers!</p>";
                        email_message += "<p>Thrive Coach</p>";

                    return email_message;
                } else if (type == 'sms-content' && section == 'admin-notification-started') {
                    let sms_message = "{{full_name}} has started a workout plan..\r\n";
                        sms_message += "Workout Plan Name: {{workout_plan_name}}.\r\n";
                        sms_message += "Client email: {{email}}}.\r\n";
                        sms_message += "You can always chat with your client via your message center or check their progress in your plan analytics\r\n";
                        sms_message += "{{plan_analytics_link}}\r\n";
                        sms_message += "Cheers!\r\n";
                        sms_message += "Thrive Coach\r\n";

                    return sms_message;
                } else if (type == 'email-subject' && section == 'admin-notification-completed') {
                    return "{{full_name}} completed {{plan_name}} workout plan.";
                } else if (type == 'email-content' && section == 'admin-notification-completed') {
                    let email_message = "<p>{{full_name}} has completed a workout plan.</p>";
                        email_message += "<p><b>Workout Plan Name:</b> {{workout_plan_name}}</p>";
                        email_message += "<p>Client email: {{email}}</p>";
                        email_message += "<p>Click to view their feedback and details</p>";
                        email_message += "<p>{{workout_plan_response_button}}</p>";
                        email_message += "<p>You can always chat with your client via your message center.</p>";
                        email_message += "<p>Cheers!</p>";
                        email_message += "<p>Thrive Coach</p>";

                    return email_message;
                } else if (type == 'sms-content' && section == 'admin-notification-completed') {
                    let sms_message = "{{full_name}} has completed a workout plan.\r\n";
                        sms_message += "Workout Plan Name: {{workout_plan_name}}.\r\n";
                        sms_message += "Client email: {{email}}}.\r\n";
                        sms_message += "Click to view their feedback and details.\r\n";
                        sms_message += "{{workout_plan_response_link}}\r\n";
                        sms_message += "You can always chat with your client via your message center\r\n";
                        sms_message += "Cheers!\r\n";
                        sms_message += "Thrive Coach\r\n";

                    return sms_message;
                }

                return '';
            },

            handleReminderDefaultEmail () {
                const vm = this;

                vm.form.reminder.email_subject  = vm.getDefaultMessage('email-subject', 'reminder');
                vm.form.reminder.email_message  = vm.getDefaultMessage('email-content', 'reminder');

                vm.reminderEmail.subject  = vm.form.reminder.email_subject;
                vm.reminderEmail.email    = vm.form.reminder.email_message;
            },

            handleReminderDefaultSms () {
                const vm = this;

                vm.form.reminder.sms_message    = vm.getDefaultMessage('sms-content', 'reminder');
            },

            handleNotificationDefaultEmail () {
                const vm = this;

                vm.form.reminder.notification_email_subject  = vm.getDefaultMessage('email-subject', 'admin-notification-started');
                vm.form.reminder.notification_email_message  = vm.getDefaultMessage('email-content', 'admin-notification-started');

                vm.notificationEmail.subject  = vm.form.reminder.notification_email_subject;
                vm.notificationEmail.email    = vm.form.reminder.notification_email_message;
            },

            handleCompletedNotificationDefaultEmail () {
                const vm = this;

                vm.form.reminder.completed_notification_email_subject  = vm.getDefaultMessage('email-subject', 'admin-notification-completed');
                vm.form.reminder.completed_notification_email_message  = vm.getDefaultMessage('email-content', 'admin-notification-completed');

                vm.completedNotificationEmail.subject  = vm.form.reminder.completed_notification_email_subject;
                vm.completedNotificationEmail.email    = vm.form.reminder.completed_notification_email_message;
            },

            handleNotificationDefaultSms () {
                const vm = this;

                vm.form.reminder.notification_sms_message    = vm.getDefaultMessage('sms-content', 'admin-notification-started');
            },

            handleCompletedNotificationDefaultSms () {
                const vm = this;

                vm.form.reminder.completed_notification_sms_message    = vm.getDefaultMessage('sms-content', 'admin-notification-completed');
            },

            handleNotificationEmailRecipient (recipient) {
                const vm = this;

                vm.form.reminder.notification_email_recipient = recipient;
            },

            handleNotificationSMSRecipient (recipient) {
                const vm = this;

                vm.form.reminder.notification_sms_recipient = recipient;
            },

            handleCompletedNotificationEmailRecipient (recipient) {
                const vm = this;

                vm.form.reminder.completed_notification_email_recipient = recipient;
            },

            handleCompletedNotificationSMSRecipient (recipient) {
                const vm = this;

                vm.form.reminder.completed_notification_sms_recipient = recipient;
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;

                params.reminder.email_subject                           = vm.reminderEmail.subject;
                params.reminder.email_message                           = vm.reminderEmail.email;
                params.reminder.show_signature                          = vm.reminderEmail.is_signature;
                params.reminder.signature_id                            = vm.reminderEmail.signature_id;
                params.reminder.show_email_logo                         = vm.reminderEmail.show_email_logo;
                params.reminder.logo_image                              = vm.reminderEmail.email_logo;
                params.reminder.notification_email_subject              = vm.notificationEmail.subject;
                params.reminder.notification_email_message              = vm.notificationEmail.email;
                params.reminder.notification_show_signature             = vm.notificationEmail.is_signature;
                params.reminder.notification_signature_id               = vm.notificationEmail.signature_id;
                params.reminder.show_notification_email_logo            = vm.notificationEmail.show_email_logo;
                params.reminder.notification_logo_image                 = vm.notificationEmail.email_logo;
                params.reminder.completed_notification_email_subject    = vm.completedNotificationEmail.subject;
                params.reminder.completed_notification_email_message    = vm.completedNotificationEmail.email;
                params.reminder.completed_notification_show_signature   = vm.completedNotificationEmail.is_signature;
                params.reminder.completed_notification_signature_id     = vm.completedNotificationEmail.signature_id;
                params.reminder.show_completed_notification_email_logo  = vm.completedNotificationEmail.show_email_logo;
                params.reminder.completed_notification_logo_image       = vm.completedNotificationEmail.email_logo;

                params.reminder.email_button_bg_color                           = vm.reminderEmail.email_button_bg_color;
                params.reminder.email_button_text_color                         = vm.reminderEmail.email_button_text_color;
                params.reminder.notification_email_button_bg_color              = vm.notificationEmail.email_button_bg_color;
                params.reminder.notification_email_button_text_color            = vm.notificationEmail.email_button_text_color;
                params.reminder.completed_notification_email_button_bg_color    = vm.completedNotificationEmail.email_button_bg_color;
                params.reminder.completed_notification_email_button_text_color  = vm.completedNotificationEmail.email_button_text_color;

                params.setFieldError = setFieldError;

                const weekSettingComponent = vm.$refs['plan-week-setting-component'];

                if (weekSettingComponent) {
                    if (weekSettingComponent.handleValidate()) {
                        vm.saveWorkoutPlan(params).then((result) => {
                            if (result && vm.planStep < 4) {
                                if (vm.switchStep) {
                                    vm.planStep = vm.planStep + 1;
                                    vm.lastStep = vm.planStep;
                                }
                            } else if (result && vm.planStep == 4) {
                                if (vm.switchStep) {
                                    vm.closeModal();
                                }
                            }

                            vm.switchStep = true;
                        });
                    } else {
                        vm.planStep = 1;
                        vm.lastStep = vm.planStep;
                        Toastr.error('Unable to submit form, Please correct all the errors!');
                    }
                } else {
                    vm.saveWorkoutPlan(params).then((result) => {
                        if (result && vm.planStep < 4) {
                            if (vm.switchStep) {
                                vm.planStep = vm.planStep + 1;
                                vm.lastStep   = vm.planStep;
                            }
                        } else if (result && vm.planStep == 4) {
                            if (vm.switchStep) {
                                vm.closeModal();
                            }
                        }

                        vm.switchStep = true;
                    });
                }
            },

            handleDeletePlan (ids) {
                const vm  = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this workout plan`, 'Delete');
                      options.preConfirm = function () {
                                                return vm.deleteWorkoutPlan({ ids }).then((result) => {
                                                    if (result) {
                                                        vm.closeModal();
                                                        vm.resetForm();
                                                    }
                                                });
                                            };

                Swal.fire(options);
            },

            goTo (refName) {
                const vm = this;

                if (vm.isNew) {
                    return false;
                }

                setTimeout(function () {
                    if ( vm.planStep != 1 && vm.planStep != 2 ) {
                        setTimeout(function () {
                            let element = vm.$refs[refName];

                            setTimeout(function() {
                                let top = element.offsetTop;
                                // document.querySelector('.preview_content .content_area').scrollTo(0, top);
                            }, 100);
                        }, 10);
                    }
                }, 10);
            },

            setting (refName) {
                const vm = this;

                const section = ['cvrsetting', 'ftrsetting', 'subbtnsetting', 'formsetting', 'thankyousetting'];

                if (section.includes(refName)) {
                    vm.planStep = refName === 'formsetting' ? 1 : 2;

                    setTimeout(function () {
                        let element = vm.$refs[refName];

                        setTimeout(function () {
                            if (element) {
                                let top = element.offsetTop;
                                // document.querySelector('.global_setting .tabs_content').scrollTo(0, top);
                            }
                        }, 100);
                    }, 100);
                }
            },

            updateSmsText () {
                const vm = this;

                const messageSms                = vm.$refs['message-sms-component'];
                const notificationSms           = vm.$refs['notification-sms-component'];
                const completedNotificationSms  = vm.$refs['completed-notification-sms-component'];

                if (messageSms) {
                    vm.messageSmsText = messageSms.smsMedia && messageSms.smsMedia.media && messageSms.smsMedia.media[0] ? vm.form.reminder.sms_message + '\n\n' + messageSms.smsMedia.media[0].toString() : vm.form.reminder.sms_message;
                }

                if (notificationSms) {
                    vm.notificationSmsText = notificationSms.smsMedia && notificationSms.smsMedia.media && notificationSms.smsMedia.media[0] ? vm.form.reminder.notification_sms_message + '\n\n' + notificationSms.smsMedia.media[0].toString() : vm.form.reminder.notification_sms_message;
                }

                if (completedNotificationSms) {
                    vm.completedNotificationSmsText = completedNotificationSms.smsMedia && completedNotificationSms.smsMedia.media && completedNotificationSms.smsMedia.media[0] ? vm.form.reminder.notification_sms_message + '\n\n' + completedNotificationSms.smsMedia.media[0].toString() : vm.form.reminder.notification_sms_message;
                }
            },

            handleCopy (string) {
                copyText(string, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                });
            },

            resizeEventHandler (event, mounted = false) {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 768) {
                    vm.preview = false;
                } else if (vm.planStep == 1 && mounted) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            toggleSection (ev) {
                const vm = this;
                let parentElm = ev.currentTarget.closest('.section_content');

                if (parentElm.classList.contains('show')) {
                    parentElm.classList.remove('show');
                    vm.toggleAll = 1;
                } else {
                    parentElm.classList.add('show');
                    vm.toggleAll = 0;
                }
            },

            openAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for(let i = 0; i < allParentElm.length; i++){
                    allParentElm[i].classList.add('show');
                }

                vm.toggleAll = 0;
            },

            closeAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('show');
                }

                vm.toggleAll = 1;
            },

            handleTutorialVideo () {
                const vm = this;

                if (vm.workoutStep == 2) {
                    vm.tutorialVideo = 'https://www.loom.com/embed/e3f62eccee514aa08f7fa6cc859de09b?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
                } else if (vm.workoutStep == 3) {
                    vm.tutorialVideo = 'https://www.loom.com/embed/1312b54cdb6c490290768da87bef3d87?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
                } else if (vm.workoutStep == 4) {
                    vm.tutorialVideo = 'https://www.loom.com/embed/8fb6743bf9ed44a4a967693300807b3d?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
                } else if (vm.workoutStep == 5) {
                    vm.tutorialVideo = 'https://www.loom.com/embed/3aca8fab368a490f94e17f53bf1586fc?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
                }

                vm.tutorial = true;

            },

            handleAutoSaveForm () {
                const vm          = this;
                const saveButton  = document.getElementById('plan-save-button');

                if (saveButton) {
                    vm.switchStep   = false;
                    vm.closeWindow  = false;
                    saveButton.click();
                }
            },

            closeDescription (e) {
                const vm = this;

                if (!e.target.classList.contains('multiselect-tag-remove-icon') && !e.target.classList.contains('save_btn')) {
                    vm.showDescription = false;
                }
            },

            handleRenameWorkoutPlanName () {
                const vm = this;

                const renameForm = vm.$refs['rename-workout-plan-form'];
                const wizardForm = vm.$refs['plan-wizard-form'];

                renameForm.validate().then((result) => {
                    if (result.valid) {
                        vm.handleRenameWorkoutPlan(renameForm);
                    }
                });
            },

            handleRenameWorkoutPlan (form) {
                const vm = this;

                vm.loader             = true
                vm.form.setFieldError = form.setFieldError;

                vm.renameWorkoutPlan(vm.form).then((result) => {
                    vm.loader = false;

                    if (result) {
                        vm.refreshWorkoutPlan();
                        vm.editWorkoutPlanName = false;
                        // vm.closeModal();

                        const wizardForm = vm.$refs['plan-wizard-form'];
                        wizardForm.setFieldError('name', '');
                    }
                });
            },

            handleCreateCategory () {
                const vm = this;

                const categoryForm = vm.$refs['category-form'];

                categoryForm.validate().then((result) => {
                    if (result.valid) {
                        vm.categoryForm.setFieldError = categoryForm.setFieldError;

                        vm.createWorkoutCategory(vm.categoryForm).then((result) => {
                            if (result) {
                                vm.createCategory    = false;
                                vm.categoryForm.name = '';
                                vm.getWorkoutCategories({ is_workout_plan: 1 });
                            }
                        });
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .plan-wizard-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .progress_item {
        border-radius: 10px;
        max-width: 400px;
        margin: 0 auto;
        border: 1px solid #e0e0e0;
    }

    .progress_item .item_img img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
    }

    .progress_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .progress_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .progress_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .progress_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .step_name {
        display: flex;
        align-items: center;
    }

    .step_name h3 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #121525;
        padding: 5px 0;
        transition: all 0.3s ease-in-out;
    }

    .step_wpr h3 {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #32373b;
        padding: 5px 0;
    }

    .sec_header {
        font-size: 17px;
        line-height: 25px;
        font-weight: 500;
        color: #32373b;
        padding: 5px 0;
    }

    .track_list {
        padding: 15px;
    }

    .track_list li {
        padding: 0 20px 20px 40px;
        position: relative;
    }

    .track_list li:not(:last-child):after {
        position: absolute;
        content: '';
        left: 14px;
        top: 0;
        bottom: 0;
        border-left: 2px dashed #b0d1ff;
    }

    .step_number {
        position: absolute;
        left: 4px;
        top: 0;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2f7eed;
        border: 3px solid #b0d1ff;
        color: #fff;
        z-index: 1;
        font-size: 9px;
        transition: all 0.3s ease-in-out;
    }

    .track_list li .step_name.active h3 {
        font-size: 15px;
        line-height: 20px;
    }

    .track_list li .step_name.active .step_number {
        font-size: 13px;
        left: 0;
        width: 30px;
        height: 30px;
    }

    .time_listing {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -7px;
    }

    .time_listing li {
        padding: 5px 7px;
        width: 20%;
    }

    .time_listing li .checkbox {
        display: block;
    }

    .selection_box {
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        border: 1px solid #d9d9d9;
        color: #121525;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .time_listing li .checkbox input[type=radio]:checked~.selection_box {
        border: 1px solid #b0d1ff;
        background: rgba(47, 126, 237, 0.05);
        color: #2f7eed;
    }

    .selection_box:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }

    .day_type {
        display: flex;
        flex-direction: column;
        margin: 0 -15px;
    }

    .day_type li {
        padding: 10px 15px;
        flex: 1 0 50%;
    }

    .day_type li .day_wpr {
        display: flex;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }

    .day_type li .day_wpr label {
        min-width: 50px;
        height: auto;
        background: #fff;
        border-right: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px 0 0 5px;
        cursor: pointer;
    }

    .day_type li .day_wpr label span {
        width: 20px;
        height: 20px;
        border: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 3px;
        background: rgba(255, 255, 255, 0.3);
    }

    .day_type li .day_wpr label span i {
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .day_type li .day_wpr label input[type=radio]:checked~span i {
        transform: scale(1);
    }

    .day_type li .day_wpr h4 {
        font-size: 13px;
        line-height: 30px;
        padding: 7px 15px;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .sqr_input {
        width: 30px;
        height: 22px;
        border: 1px solid #d9d9d9;
        margin: 0 5px;
        text-align: center;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-size: 12px;
        font-weight: 500;
    }

    .date_input {
        width: 140px;
        height: 25px;
        border: 1px solid #d9d9d9;
        margin: 0 5px;
        padding: 0 5px;
        text-align: left;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-size: 13px;
        font-weight: 500;
    }

    .multiselect.small_box {
        width: 80px;
        min-height: 25px;
        margin: 0 7px;
        font-size: 12px;
    }

    .sqr_input::-webkit-outer-spin-button,
    .sqr_input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .sqr_input[type=number] {
        -moz-appearance: textfield;
    }

    .time_selection {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    .time_selection .form_grp {
        padding: 10px;
        width: 50%;
        margin: 0;
        align-items: flex-end;
    }

    .day_sorting {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -5px;
    }

    .day_sorting li {
        padding: 5px;
        width: 25%;
        min-width: 90px;
    }

    .day_sorting li label {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 15px;
    }

    .circumference_list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 15px -10px;
    }

    .circumference_list li {
        padding: 10px;
        width: 50%;
    }

    .circumference_list li label {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        padding: 15px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .circumference_list li label:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .custom_list li {
        border: 1px solid #eaeaea;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 30px;
    }

    .custom_list li h5.category_bar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-weight: 500;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        padding: 0 15px;
        column-gap: 15px;
    }

    .category_bar .capsule_btn {
        border-right: 1px solid #d9d9d9;
    }

    .category_bar input {
        width: 100%;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        margin-right: 12px;
        padding: 15px 0;
    }

    .category_bar.active input,
    .category_bar input:focus {
        background: #f7faff;
        border-left: 1px solid #e9e9e9;
        border-right: 1px solid #e9e9e9;
        padding: 15px;
    }

    .category_bar .add_btn {
        font-size: 11px;
        line-height: 16px;
        margin: 0 0 0 15px;
        padding-right: 15px;
        border-right: 1px solid #d9d9d9;
    }

    .category_bar .add_btn i {
        font-size: 10px !important;
    }

    .category_bar .delete_btn {
        color: #eb1414;
        font-size: 11px;
        line-height: 16px;
        margin: 0 0 0 15px;
        cursor: pointer;
    }

    .category_bar .bar_text {
        display: flex;
        align-items: center;
        flex-grow: 1;
        position: relative;
    }

    .category_bar .bar_text:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border-bottom: 1px solid #e9e9e9;
    }

    .category_bar .bar_actions {
        display: flex;
        align-items: center;
        height: 50px;
    }

    .custom_list li h5 span.save {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .custom_list li h5 label {
        padding: 0 15px 0 0;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: auto;
        border-right: 1px solid #d9d9d9;
    }

    .custom_list li h5 label.has {
        color: #2f7eed;
    }

    .custom_list li h5 .edit_btn {
        font-size: 16px;
        color: #2F7FED;
        margin: 5px 0px;
        background: transparent;
        padding: 0px;
    }

    .custom_list li .section_wpr {
        border-top: 1px solid #eaeaea;
        margin: 10px -15px 0 -15px;
        padding: 10px 30px;
    }

    .sub_categories {
        padding: 30px 20px;
        border: 1px solid #eaeaea;
        background: #fafafa;
        border-radius: 6px;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .sub_categories h6 {
        font-size: 13px;
        line-height: 18px;
        color: #999;
        font-weight: 400;
        text-align: center;
    }

    .category_bar .quest_title {
        flex: 1 1 auto;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        margin-right: 12px;
        padding: 15px 0;
    }

    .prog_edit {
        background: #fff;
        padding: 20px 30px 30px 30px;
        border: 1px solid #eaeaea;
        border-radius: 6px;
        margin-top: 10px;
        cursor: auto;
    }

    .prog_edit :deep(.tab_content) {
        height: auto;
    }

    .prog_edit .field_wpr textarea {
        padding: 10px 15px;
        font-size: 13px;
        line-height: 18px;
        height: 100px;
        background: transparent;
        resize: none;
    }

    .grp_wpr {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        padding: 20px 0 5px 0;
    }

    .grp_wpr label .box_item {
        font-size: 13px;
        line-height: 15px;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }

    .grp_wpr label .box_item i {
        font-size: 15px;
    }

    .grp_wpr label.yes .box_item {
        border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e;
    }

    .grp_wpr label.no .box_item {
        border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414;
    }

    .grp_wpr label input[type="radio"]:checked~.box_item {
        opacity: 1;
    }

    .question-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 25px 0 5px;
        gap: 20px;
    }

    .question-options label {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }

    .question-options label p {
        font-size: 13px;
        line-height: 18px;
        padding-left: 12px;
    }

    .question-options .radio_box {
        width: 13px;
        height: 13px;
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        position: relative;
        display: block;
        margin-top: 3px;
    }

    .question-options .radio_box .dot {
        position: absolute;
        background: #5a5a5a;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .question-options label input[type="radio"]:checked~.radio_box .dot {
        transform: scale(1);
    }


    .modal.add_question .modal_header {
        padding: 0 45px;
    }

    .modal.add_question .modal_body {
        padding: 30px 45px 35px 45px;
        display: block;
    }

    .mcq_list li {
        border: 1px solid #d9d9d9;
        padding: 0 15px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .mcq_list li input {
        padding: 12px 15px 10px 0;
        font-size: 14px;
        line-height: 19px;
    }

    .mcq_list li button {
        border-left: 1px solid #d9d9d9;
        padding: 0 0 0 15px;
    }

    .col-2 {
        display: flex;
        margin: 0 -15px;
    }

    .col-2 .color_palette {
        flex: 1 0 50%;
        padding: 10px 15px;
    }

    .preview_content {
        width: 40%;
        position: relative;
        margin-right: -40%;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 2;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }

    .tab_sec li {
        display: flex;
        align-items: center;
    }

    .tab_sec .tag {
        padding: 2px 5px;
        border-radius: 7px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
    }

    .cover_type {
        margin: 15px -35px 15px -35px;
        background: #fbfbfb;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 35px;
    }

    .cover_type .type_list {
        justify-content: space-around;
        margin-top: 15px;
    }

    .days {
        display: flex;
        padding: 15px 0;
        gap: 7px;
    }

    .cell .days {
        border-top: 1px solid #E6E6E6;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0 20px;
    }

    .days li {
        padding: 4px 0;
        flex: 0 1 55px;
    }

    .days li label {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 10px 6px 12px 6px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
    }

    .days li label span {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .days li label .tick_box {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #5a5a5a;
        position: relative;
        margin-top: 10px;
    }

    .days li label .tick_box:after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #5a5a5a;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .days li label input {
        margin-right: 7px;
        opacity: 0;
    }

    .days li label.active {
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid #b0d1ff;
    }

    .days li label.active .tick_box:after {
        transform: scale(1);
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .cell:after,
    .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }

    .cell .msg_preview:after,
    .cell .sms_preview::after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview p,
    .cell .sms_preview p {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    .hourly_info {
        padding: 15px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        background: #fff;
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #757575;
        text-align: center;
    }

    .form_grp :deep(.multiselect-single-label span) {
        font-size: 13px;
    }

    :deep(.cell .dashboard_footer) {
        width: auto;
        text-align: center;
        padding: 20px 0;
    }

    :deep(.cell .dashboard_footer ul li) {
        padding: 2px;
    }

    :deep(.cell .dashboard_footer ul li a) {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }

    :deep(.cell .dashboard_footer h4) {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    :deep(.cell .dashboard_footer p) {
        font-size: 9px;
        line-height: 11px;
    }

    .info_bar {
        display: flex;
        flex-direction: column;
        /* background: #fff;
        border: 1px solid #e9e9e9; */
        /* border-radius: 10px; */
        max-width: 400px;
        margin: 0 auto;
        position: relative;
    }
    .info_bar .tutorial_btn{
        position: absolute;
        left: 100%;
        top: 25px;
        padding: 4px 10px;
        background: #fff;
        border-radius: 11px;
        font-size: 11px;
        line-height: 14px;
        color: #2f7eed;
        font-weight: 500;
        margin-left: 5%;
        cursor: pointer;
    }

    .time {
        min-width: 150px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 10px 25px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        display: none;
    }

    .time span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: block;
    }

    .step_bar2 {
        position: relative;
        padding: 20px 15px 10px 15px;
    }

    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }

    .reset-default {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 15px;
        color: #747474;
        font-weight: 400;
        cursor: pointer;
        position: relative;
    }

    .cover-header-with-text {
        display: flex;
    }

    .cover-header-with-text .small-cover-info {
        margin: 0;
        margin-left: 10px;
    }

    .cover-header-with-text div>small {
        font-size: 12px;
    }

    .flex-center {
        display: flex;
        align-items: center;
    }


    /* toggle_section */
    .toggle_section {
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }

    .toggle_section i {
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    .global_setting .tabs_content .section_content {
        position: relative;
    }

    .global_setting .tabs_content .section_content .section_header {
        margin: 10px 0;
    }

    .global_setting .tabs_content .section_content:after {
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right: 20px;
        border-bottom: 1px solid #e9e9e9;
    }

    .global_setting .tabs_content .section_content.show:after,
    .global_setting .tabs_content .section_content:last-of-type:after,
    .global_setting .tabs_content .section_content.open_area:after {
        border: 0;
    }

    .global_setting .tabs_content .section_content .toggle_content {
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .global_setting .tabs_content .section_content.show .toggle_content {
        max-height: 1000vh;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .global_setting .tabs_content .section_content.show .toggle_section i {
        transform: rotate(-180deg);
    }

    /* --- */

    .section_content .section_header .status {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }

    .section_content .section_header .switch_option {
        flex: 1 1 auto;
    }

    .section_header h2 span {
        font-size: 13px;
        line-height: 17px;
        color: #f2a31d;
        display: block;
        padding-top: 7px;
    }

    .section_header h2 a {
        font-size: 13px;
        line-height: 17px;
        color: #121525;
        display: block;
        padding: 7px 7px 0 7px;
        position: relative;
    }

    .section_header h2 a .quick_info {
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        max-width: 200px;
        min-width: 100px;
        text-align: center;
        position: absolute;
        left: 0;
        top: auto;
        bottom: 100%;
        display: none;
        margin-bottom: 10px;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
    }

    .section_header h2 a .quick_info:after {
        content: "";
        position: absolute;
        bottom: -6px;
        top: auto;
        left: 10px;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }

    .section_header h2 a:hover .quick_info {
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .btn_list {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
    }

    .btn_list li {
        background: #fff;
        border-radius: 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 3px 10px;
        cursor: pointer;
    }

    @media(max-width: 1499px) {
        .time_selection .form_grp {
            flex: 0 0 100%;
            width: 100%;
        }

        .selection_box {
            font-size: 11px;
            line-height: 15px;
        }

        .time_listing li {
            padding: 3px;
        }

        .tab_sec li {
            font-size: 12px;
            line-height: 15px;
            padding: 8px 12px;
        }
    }

    :deep(.weekdays .multiselect-single-label-text) {
        text-transform: capitalize;
    }

    :deep(.question_list) {
        display: block;
        margin: 0;
    }

    :deep(.question_list li) {
        width: 100%;
    }

    :deep(.question_list li h5 label) {
        margin-right: auto;
        margin-left: 0;
        padding-left: 0;
    }

    .grp_wpr.grp_option {
        display: flex;
        flex-wrap: wrap;
    }

    .box_item {
        flex: 1 0 calc(50% - 10px);
    }

    /* .box_item label {
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 5px 7px;
        display: flex;
        align-items: center;
    } */

    .mrl-5 {
        margin: 0 -5px;
    }

    .p-10-15 {
        padding: 10px 15px !important;
    }

    :deep(.dp__input_wrap input) {
        font-size: 12px;
        color: #5a5a5a;
    }

    .tab_slider {
        margin: 0 -15px;
    }

    :deep(.dp__main .dp__input) {
        font-size: 11px;
        max-width: 120px;
        padding: 0 0 0 35px !important;
    }

    .addblock_section {
        position: relative;
        width: 100%;
        padding: 0 10px;
    }

    .addblock_section .add_types {
        max-width: 572px;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, .1);
        text-align: center;
        z-index: 2;
    }

    .addblock_section .add_types:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }

    .addblock_section .add_types ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        padding: 30px 20px;
    }

    .addblock_section .add_types ul li {
        flex: 0 1 80px;
    }

    .addblock_section .add_types ul li .card_item {
        padding: 15px 5px;
    }

    .addblock_section .add_types .title {
        padding: 15px 30px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        color: #121525;
    }

    .block_list .block_actions {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .block_list .block_actions button {
        font-size: 15px;
        color: #121525;
        cursor: pointer;
    }

    .block_list .block_actions button.up_down {
        color: #999;
    }

    .block_list .block_actions .block_setting {
        position: relative;
        cursor: pointer;
    }

    .block_list .block_actions .block_setting i {
        font-size: 15px;
        color: #999;
    }

    .block_list .block_actions .block_setting .dropdown_wpr {
        min-width: 250px;
        max-height: 350px;
        overflow-y: scroll;
        right: 1px;
        left: auto;
        padding: 15px;
        margin-bottom: 10px;
        z-index: 4;
    }

    .block_list .block_actions .block_setting .dropdown_wpr::-webkit-scrollbar {
        display: none;
    }

    .block_list .block_actions .block_setting .dropdown_wpr:before {
        display: none;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .sec_label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        padding: 3px 0;
        display: block;
    }

    .block_list .block_actions .block_setting .dropdown_wpr ul li {
        padding-top: 13px;
        border: 0;
        background: #f5f5f5;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        margin-bottom: 10px;
        border-radius: 5px;
    }

    .block_list .block_actions .block_setting .dropdown_wpr ul li .switch_option h5 {
        font-weight: 400;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .group_item .input_label {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr,
    .block_list .block_actions .block_setting .dropdown_wpr .form_grp .multiselect {
        background: #fff;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr input,
    .block_list .block_actions .block_setting .dropdown_wpr .form_grp .multiselect {
        height: 30px;
        min-height: 30px;
        font-size: 11px;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr.has_suffix {
        padding-right: 40px;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr.has_suffix .suffix {
        width: 40px;
        font-size: 11px;
        line-height: 14px;
        background: #f5f5f5;
        border-radius: 0 5px 5px 0;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown) {
        min-width: 100%;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown ul.multiselect-options li) {
        padding: 5px 10px;
        font-size: 11px;
        line-height: 15px;
    }

    .block_list li .block_panel {
        border: 1px solid #eaeaea;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 30px;
        margin-bottom: 40px;
        transition: all 0.3s ease-in-out;
    }

    .block_list li .block_panel.active {
        border: 1px solid #c8dfff;
        background: #fbfbfb;
    }

    .block_panel li.add_item button {
        width: 150px;
        border-radius: 6px;
        background: #fff;
        border: 1px solid #f5f5f5;
        padding: 20px 10px;
        min-height: 134px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        font-size: 13px;
        line-height: 18px;
        gap: 10px;
        color: #999;
        cursor: pointer;
    }

    .block_library {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0;
    }

    .block_library ul.list {
        display: flex;
        flex-wrap: wrap;
        margin: 20px -10px;
        width: 100%;
    }

    .block_library ul.list.shake {
        animation: headShake 0.3s linear 0s infinite alternate;
    }

    .block_library ul.list>li {
        padding: 20px 10px;
        width: 33.333%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: relative;
    }

    .video_wpr {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 5px;
        overflow: hidden;
    }

    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }

    .block_library ul li h6 {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
    }

    .block_library ul li .action_items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 10px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .block_library ul li:hover .action_items,
    .block_library ul li.selected .action_items,
    .block_library ul.target li .action_items {
        opacity: 1;
    }

    .block_library ul li .action_items label.checkbox span {
        width: 12px;
        flex: 0 0 12px;
        height: 12px;
        border-radius: 2px;
    }

    .block_library ul li .action_items label.checkbox span i {
        font-size: 8px;
    }

    .block_library ul li .action_items a {
        cursor: pointer;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
    }

    .block_library ul li .action_items a i {
        font-size: 9px;
        padding-right: 4px;
    }

    .block_library .library_header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .block_library .library_header a {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: pointer;
    }

    .block_library .filter_search {
        /* border: 1px solid #efefef; */
        height: 34px;
        border-radius: 20px;
        padding: 0 34px 0 0;
        position: relative;
        background: #fbfbfb;
        max-width: 400px;
        width: 100%;
    }

    .block_library .filter_search input {
        font-size: 11px;
        line-height: 34px;
        color: #666;
        border: 0;
        width: 100%;
        padding: 0 15px;
        background: transparent;
        box-sizing: border-box;
    }

    .block_library .filter_search .search_btn {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 0;
        font-size: 11px;
        color: #333;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .block_library .filters {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 25px 0 10px 0;
        position: relative;
        z-index: 2;
    }

    .block_library .filters h5 {
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 400;
    }

    .filters .filter_item,
    .attribute_list {
        position: relative;
        font-size: 11px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        cursor: pointer;
    }

    .filters .filter_item li,
    .other_filter_items li {
        font-size: 11px;
        line-height: 15px;
    }

    .other_filter_items {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f1f1;
        border-radius: 50%;
        font-size: 10px;
        position: relative;
        cursor: pointer;
    }

    .attribute_bar {
        padding: 15px 0;
        background: #fff;
        border-left: 1px solid #e9e9e9;
        width: 230px;
        max-height: 100vh;
        overflow-y: overlay;
        position: relative;
        margin-right: -230px;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
    }

    .attribute_bar.show {
        margin-right: 0;
    }

    .attribute_bar::-webkit-scrollbar {
        width: 4px;
    }

    .attribute_bar::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #cacaca;
    }

    .attribute_bar h3 {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        padding: 0 20px 5px 20px;
        display: flex;
        align-items: center;
    }

    .attribute_bar h3 button {
        font-size: 13px;
        color: #999;
        margin-left: auto;
        cursor: pointer;
    }

    .toggle_btn {
        display: flex;
        justify-content: flex-end;
        padding: 6px 20px 12px 20px;
    }

    .toggle_btn a {
        padding: 4px 10px;
        background: #f5f5f5;
        font-size: 10px;
        line-height: 12px;
        color: #121525;
        font-weight: 500;
        display: block;
        border-radius: 10px;
        cursor: pointer;
    }

    .attribute_grp {
        border-top: 1px solid #f5f5f5;
    }

    .attribute_grp h4 {
        padding: 12px 15px;
        font-size: 13px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .attribute_grp h4 a {
        color: #5a5a5a;
        margin-right: 15px;
        font-size: 11px;
        display: flex;
        align-items: center;
    }

    .attribute_grp h4 a.pinned {
        color: #2f7eed;
    }

    .attribute_grp h4>i {
        margin-left: auto;
        font-size: 11px;
        color: #999;
        transition: all 0.3s ease-in-out;
    }

    .attribute_grp ul {
        padding: 0 15px;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .attribute_grp.active ul {
        max-height: 10000px;
    }

    .attribute_grp.active h4>i {
        transform: rotate(-180deg);
    }

    .attribute_bar ul li {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 10px 15px;
    }

    .attribute_bar ul li:last-child {
        margin-bottom: 15px;
    }

    .attribute_bar ul li label.checkbox,
    .dropdown_wpr ul li label.checkbox {
        justify-content: flex-start;
        gap: 10px;
        cursor: pointer;
    }

    .add_attribute {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 10px 20px;
        background: #2f7eed;
        color: #fff;
        border-radius: 5px;
        margin: auto 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        gap: 7px;
        cursor: pointer;
    }

    .add_attribute i {
        font-size: 9px;
    }

    .filters .attribute_bar {
        position: fixed;
        right: -230px;
        top: 0;
        height: 100vh;
        overflow-y: scroll;
        transition: all 0.3s ease-in-out;
    }

    .filters .attribute_bar.show {
        right: 0;
    }

    .search_tags {
        background: #fbfbfb;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        margin-top: 20px;
    }

    .search_tags li {
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: relative;
    }

    .search_tags .delete_btn {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .search_tags li:hover .delete_btn {
        opacity: 1;
    }

    .dropdown_wpr ul li label.checkbox span i {
        margin: 0;
    }

    .attribute_bar ul li label.checkbox span.active i,
    .dropdown_wpr ul li label.checkbox span.active i {
        transform: scale(1);
        opacity: 1;
    }

    .bulk_action {
        border-right: 1px solid #d9d9d9;
        padding-right: 20px;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .bulk_action .delete_btn {
        color: #eb1414;
        cursor: pointer;
    }

    .filters .bulk_action .filter_item,
    .filters .bulk_action .filter_item i {
        color: #2f7eed;
    }

    .filter_item i {
        font-size: 9px;
        padding: 5px;
        vertical-align: top;
    }

    .filters .filter_item i,
    .attribute_list i {
        color: #7a7a7a;
        pointer-events: none;
    }

    .filters .filter_item .dropdown_wpr,
    .attribute_list .dropdown_wpr {
        min-width: 160px;
        overflow-y: hidden;
    }

    .filters .filter_item .dropdown_wpr ul,
    .attribute_list .dropdown_wpr ul {
        max-height: 400px;
        overflow-y: scroll;
    }

    .attribute_list .dropdown_wpr ul {
        max-height: 200px;
        overflow-y: scroll;
    }

    .filters .filter_item .dropdown_wpr ul::-webkit-scrollbar,
    .other_filter_items .dropdown_wpr::-webkit-scrollbar,
    .attribute_list .dropdown_wpr ul::-webkit-scrollbar,
    .attribute_list .dropdown_wpr ul::-webkit-scrollbar {
        display: none;
    }

    .filters .filter_item .dropdown_wpr ul li,
    .other_filter_items .dropdown_wpr ul li,
    .attribute_list .dropdown_wpr ul li {
        border: 0;
    }

    .filters .filter_item .dropdown_wpr ul li.active {
        background: #f5f5f5;
    }

    .dropdown_wpr ul li.dropdown-header {
        background: #e9e9e9;
    }

    .block_list li .block_panel ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    .block_list li .block_panel ul li {
        padding: 10px 15px;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        position: relative;
    }

    .block_list li .block_panel ul li .block_action {
        position: absolute;
        right: 15px;
        bottom: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }

    .block_list li.circuit .block_panel ul li .block_action {
        right: 30px;
    }

    .block_list li .block_panel ul li .block_action button {
        font-size: 11px;
        color: #eb1414;
        cursor: pointer;
    }

    .block_list li .block_panel ul li:hover .block_action {
        opacity: 1;
    }

    .block_list li .block_panel ul li h6 {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .block_list li .block_panel ul li h6 .v_tag {
        width: 14px;
        height: 14px;
        background: #999;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        line-height: 10px;
        font-weight: 500;
        color: #fff;
        margin-right: 5px;
        border-radius: 50%;
    }

    .block_list li .block_panel ul li.rest_list {
        width: auto;
    }

    .block_list li.circuit .block_panel ul li {
        padding-right: 30px;
    }

    .block_list li .block_panel ul li .circuit {
        width: 40px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        position: absolute;
        font-size: 15px;
        color: #2f7eed;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
        z-index: 1;
        gap: 5px;
        visibility: hidden;
    }

    .block_list li .block_panel ul li .circuit a {
        font-size: 10px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        width: 40px;
        text-align: center;
    }

    .block_list li.circuit .block_panel ul li .circuit {
        visibility: visible;
    }

    /* .block_list li:not(.circuit) .block_panel ul li:nth-child(3):before{
            width: 22px;
            height: 22px;
            background: #2f7eed;
            border-radius: 11px;
            content: "SS";
            position: absolute;
            font-size: 10px;
            font-weight: 500;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            letter-spacing: 0.5px;
            top: 50%;
            left: -11px;
            transform: translateY(-50%);
            z-index: 1;
        } */

    .rest_list {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rest_circle {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #ffc4c4;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        cursor: pointer;
    }

    .rest_circle span {
        font-size: 15px;
        line-height: 20px;
    }

    .edit_rest {
        width: 230px;
        position: absolute;
        top: 100%;
        right: 1px;
        left: auto;
        margin-top: 10px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, .1);
        text-align: center;
        z-index: 4;
    }

    /* .edit_rest:after {
            position: absolute;
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            width: 20px;
            height: 13px;
            right: calc(100% - 4px);
            bottom: 50%;
            transform: rotate(90deg);
        } */
    .edit_rest .title {
        padding: 10px 20px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
    }

    .edit_rest .setting_wpr {
        padding: 20px;
    }

    .edit_rest .input_label {
        font-size: 11px;
        line-height: 13px;
    }

    .edit_rest .field_wpr input {
        height: 32px;
        font-size: 11px;
    }

    .edit_rest :deep(.color-picker .field_wpr input) {
        height: 30px;
        padding: 0 10px;
        font-size: 11px;
    }

    .edit_rest :deep(.color-picker .field_wpr.has_prefix) {
        padding-left: 30px;
    }

    .edit_rest :deep(.color-picker .field_wpr.has_prefix .prefix-right) {
        top: 3px;
        bottom: 3px;
        right: 3px;
    }

    .edit_rest :deep(.color-picker .field_wpr.has_prefix .prefix) {
        width: 30px;
        height: 30px;
    }

    .edit_rest :deep(.color-picker .pickr),
    .edit_rest :deep(.color-picker .pickr .pcr-button) {
        width: 20px;
        height: 20px;
        line-height: 0;
    }

    .edit_rest :deep(.color-picker .sub_header) {
        font-size: 11px;
        line-height: 14px;
    }

    .edit_rest :deep(.color-picker .swatches li) {
        padding: 4px;
    }

    .edit_rest :deep(.color-picker .swatches li span) {
        width: 20px;
        height: 20px;
    }

    .edit_rest :deep(.color-picker .swatches li span i) {
        font-size: 10px;
    }

    .edit_rest .action_wpr {
        padding: 12px 20px;
        border-top: 1px solid #f5f5f5;
        margin: 0;
    }

    .edit_rest .action_wpr .btn {
        min-width: auto;
        height: 30px;
        font-size: 10px;
        line-height: 28px;
        padding: 0 15px;
    }

    .edit_rest .action_wpr .btn.save_btn {
        color: #fff;
    }

    .workout_config .modal_container {
        padding: 0;
        max-width: 700px;
        width: 100%;
    }

    .workout_config .modal_header {
        padding: 20px 30px;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
    }

    .workout_config .modal_footer {
        padding: 20px 30px;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        border-radius: 0 0 10px 10px;
    }

    .workout_config .modal_header h2 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        text-align: left;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .workout_config .modal_header button {
        color: #999;
        font-size: 15px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-self: center;
    }

    .workout_config .modal_body {
        width: 100%;
        padding: 20px 30px;
        max-height: 600px;
    }

    .workout_config .edit_content {
        display: flex;
        gap: 30px;
        margin-bottom: 20px;
    }

    .workout_config .edit_content .thumb_section {
        flex: 0 1 600px;
        display: flex;
        gap: 20px;
        background: #fbfbfb;
        border-radius: 5px;
    }

    .workout_config .edit_content .thumb_section .thumb_title {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin: 15px 0;
        display: flex;
    }

    .workout_config .edit_content .thumb_section .thumb_title .v_tag {
        width: 20px;
        height: 20px;
        background: #999;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 14px;
        font-weight: 600;
        color: #fff;
        margin-left: 10px;
        border-radius: 50%;
    }

    .workout_config .edit_content .thumb_section .exercise_info {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        margin-top: 10px;
    }

    .workout_config .edit_content .video_section {
        flex: 0 1 280px;
    }

    .workout_config .edit_content .setting_wpr {
        flex: 1 0 180px;
    }

    .set_wpr {
        display: flex;
        margin: 0 -7px;
    }

    .set_wpr .single_item {
        flex: 0 1 15%;
        padding: 7px;
    }

    .set_wpr .public_name {
        flex: 0 1 294px;
        padding: 0 7px;
    }

    .set_wpr .public_descriptions {
        max-width: 544px;
        width: 100%;
        padding: 0 7px;
    }

    .set_wpr .item_wpr {
        flex: 0 1 25%;
        padding: 7px;
    }

    .set_wpr .item_wpr:last-of-type {
        margin-left: auto;
    }

    .column-2 {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
    }

    .column-2 .item {
        flex: 1 0 calc(50% - 7px);
    }

    .set_wpr .input_label {
        font-size: 14px;
        line-height: 19px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .workout_config .fld_label {
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 5px;
        display: block;
    }

    .workout_config .field_wpr input,
    .workout_config .form_grp .multiselect {
        height: 40px;
        min-height: 40px;
        font-size: 13px;
    }

    .form_grp.set_wpr .multiselect {
        min-height: 40px;
    }

    .var_list {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 10px 0;
    }

    .var_list .blank_space {
        min-width: 21%;
    }

    .radio_opt {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }

    .radio_opt p {
        font-size: 14px;
        line-height: 19px;
        padding-left: 8px;
    }

    .radio_box {
        width: 13px;
        height: 13px;
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        position: relative;
        display: block;
        margin-top: 4px;
    }

    .radio_box .dot {
        position: absolute;
        background: #5a5a5a;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .radio_opt input[type="radio"]:checked~.radio_box .dot {
        transform: scale(1);
    }

    .var_table {
        padding: 20px 0;
    }

    .var_table table {
        width: 100%;
        background: #fbfbfb;
        border-radius: 6px;
        border-collapse: collapse;
    }

    .var_table table thead {
        border-bottom: 1px solid #efefef;
    }

    .var_table table tbody tr:not(:last-child) {
        border-bottom: 1px solid #f3f3f3;
    }

    .var_table table th {
        padding: 12px 18px;
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
    }

    .var_table table td {
        padding: 10px 18px 15px 18px;
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 300;
        vertical-align: bottom;
    }

    .var_table table td .row {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .var_table table td input {
        width: 50px;
        font-weight: 300;
        height: 30px;
        border: 1px solid #CBCDD3;
        border-radius: 5px;
        padding-left: 5px;
    }

    .other_content {
        width: 100%;
        padding: 20px 0 0 0;
    }

    .group_title {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .group_title h4 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 10px;
        margin-right: auto;
    }

    .group_title h4 span {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        padding-left: 15px;
    }

    .att_tags {
        background: #fbfbfb;
        border-radius: 6px;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }

    .att_tags li {
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 7px 15px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: relative;
    }

    .att_tags .delete_btn {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .att_tags li:hover .delete_btn {
        opacity: 1;
    }

    .att_tags li.exercise,
    .search_tags li.exercise {
        border-color: #88d3d9;
    }

    .att_tags li.position,
    .search_tags li.position {
        border-color: #b99191;
    }

    .att_tags li.contraction,
    .search_tags li.contraction {
        border-color: #968bca;
    }

    .att_tags li.muscle,
    .search_tags li.muscle {
        border-color: #76afff;
    }

    .att_tags li.movement,
    .search_tags li.movement {
        border-color: #cb72ff;
    }

    .att_tags li.equipment,
    .search_tags li.equipment {
        border-color: #56e094;
    }

    .att_tags li.skeletal,
    .search_tags li.skeletal {
        border-color: #fb76c8;
    }

    .att_tags li.difficulty,
    .search_tags li.difficulty {
        border-color: #f2a31d;
    }

    .other_content .field_wpr {
        background: #fff;
    }

    .other_content textarea {
        font-size: 13px;
        line-height: 18px;
        resize: vertical;
    }

    .video-overlay {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        top: 0;
        cursor: pointer;
    }

    .section_container {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        padding: 30px 50px;
    }

    .section_title {
        font-size: 22px;
        line-height: 30px;
        color: #121525;
        font-weight: 400;
        text-align: center;
        padding: 0 0 20px 0;
    }

    .content_panel {
        border-radius: 8px;
        background: #fff;
        position: relative;
        padding: 30px;
    }

    .content_panel.circuit .set_wpr .single_item.sets,
    .content_panel.circuit .set_wpr .item_wpr.rest,
    .content_panel.circuit .form_grp :deep(ul.multiselect-options li#multiselect-option-Superset),
    .content_panel.progression .form_grp :deep(ul.multiselect-options li[aria-label="Using Time"]),
    .content_panel.no_superset .form_grp :deep(ul.multiselect-options li#multiselect-option-Superset),
    .disable {
        pointer-events: none;
        opacity: 0.7;
    }

    .global_setting .section_header h2 {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .global_setting .section_header h2 input {
        font-size: 16px;
        line-height: 20px;
        background: transparent;
        width: 200px;
        background: #f0f5ff;
        padding: 10px 15px;
        border-radius: 5px;
    }

    .global_setting .section_header h2 .save_btn {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .form_grp.small {
        margin: 0;
    }

    .form_grp.small .field_wpr {
        width: 80px;
    }

    .form_grp.small :deep(.multiselect-multiple-label),
    .form_grp.small :deep(.multiselect-placeholder),
    .form_grp.small :deep(.multiselect-single-label) {
        padding: 0 20px 0 5px;
    }

    .form_grp.small :deep(.multiselect) {
        min-height: 30px;
        height: 30px;
    }

    .form_grp.small :deep(.multiselect-single-label span) {
        font-size: 11px;
    }

    .form_grp.small :deep(.multiselect-caret) {
        margin-right: 5px;
    }

    .load_more {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        line-height: 18px;
        color: #999;
        gap: 10px;
    }

    .smart_blocklist .add_btn {
        font-size: 13px;
        line-height: 16px;
    }

    .smart_blocklist .add_btn i {
        margin-right: 5px;
        font-size: 9px !important;
    }

    .smart_blocklist>ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .smart_blocklist>ul .block_info {
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        padding: 7px 15px 20px 15px;
    }

    .smart_blocklist>ul .block_info>li {
        padding: 7px;
        width: 33.333%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .smart_blocklist>ul .block_info li h6 {
        font-size: 9px;
        line-height: 11px;
        color: #121525;
        font-weight: 400;
        margin: 5px 0;
    }

    .section_header h2 {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .section_header.active h2:not(.editable),
    .section_header h2.editable {
        display: none;
    }

    .section_header.active h2.editable {
        display: flex;
    }

    .section_header h2 input {
        font-size: 16px;
        line-height: 20px;
        background: transparent;
        width: 200px;
        background: #f0f5ff;
        padding: 10px 15px;
        border-radius: 5px;
    }

    .section_header h2 .save_btn {
        padding: 4px 10px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 9px;
        line-height: 11px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .plan-wizard-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .section_header.center {
        justify-content: center;
        margin-bottom: 20px;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content {
        width: 40%;
        position: relative;
        margin-right: -40%;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 2;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }

    .week_setting {
        position: relative;
    }

    .addblock_section {
        position: relative;
        width: 100%;
        padding: 0 10px 20px 10px;
    }

    .addblock_section .add_types {
        max-width: 252px;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, .1);
        text-align: center;
        z-index: 2;
    }

    .addblock_section .add_types:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }

    .addblock_section .add_types ul {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 20px 20px;
    }

    .addblock_section .add_types ul li {
        flex: 0 1 100px;
    }

    .addblock_section .add_types ul li .card_item {
        padding: 15px 5px;
    }

    .addblock_section .add_types .title {
        padding: 15px 30px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        color: #121525;
    }

    .week_list .week_actions {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .week_list .week_actions button {
        font-size: 15px;
        color: #121525;
        cursor: pointer;
    }

    .week_list .week_actions button.up_down {
        color: #999;
    }

    .week_list .week_actions .week_setting {
        position: relative;
        cursor: pointer;
    }

    .week_list .week_actions .week_setting i {
        font-size: 15px;
        color: #999;
    }

    .week_list .week_actions .week_setting .dropdown_wpr {
        min-width: 200px;
        right: 1px;
        left: auto;
        padding: 15px;
        margin-bottom: 10px;
        z-index: 4;
        transform: none;
    }

    .week_list .week_actions .week_setting .dropdown_wpr:before {
        display: none;
    }

    .week_list .week_actions .week_setting .dropdown_wpr ul li {
        padding-top: 13px;
        border: 0;
        background: #f5f5f5;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        margin-bottom: 10px;
        border-radius: 5px;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .group_item .input_label {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .field_wpr {
        background: #fff;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .field_wpr input {
        height: 30px;
        min-height: 30px;
        font-size: 11px;
        padding: 0 10px;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .field_wpr.has_suffix {
        padding-right: 40px;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .field_wpr.has_suffix .suffix {
        width: 40px;
        font-size: 11px;
        line-height: 14px;
        background: #f5f5f5;
        border-radius: 0 5px 5px 0;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown) {
        min-width: 100%;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown ul.multiselect-options li) {
        padding: 5px 10px;
        font-size: 11px;
        line-height: 15px;
    }

    .week_list li .week_panel {
        border: 1px solid #eaeaea;
        background: #fafafb;
        overflow: hidden;
        border-radius: 6px;
        padding: 20px 30px 30px 30px;
        margin-bottom: 40px;
        transition: all 0.3s ease-in-out;
    }

    .week_list li .week_panel ul {
        display: flex;
        flex-wrap: wrap;
    }

    .week_list li .week_panel>ul>li {
        width: 14.28%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        min-width: 175px;
    }

    .week_list li .week_panel ul li h5 {
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
    }

    .week_list li .week_panel ul li h5 i {
        font-size: 9px;
        color: #999;
        margin-left: 10px;
    }

    .week_list li .week_panel ul li .box_item {
        height: 150px;
        width: 100%;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 1px solid #f5f5f5;
    }

    .week_list li .week_panel ul li .box_item p {
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 500;
        position: relative;
        cursor: pointer;
    }

    .week_list li .week_panel ul li .box_item ol {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .week_list li .week_panel ul li .box_item ol>li {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: top;
        gap: 5px;
        position: relative;
    }

    .week_list li .week_panel ul li .box_item ol>li>label {
        background: #f5f5f5;
        border-radius: 12px;
        display: flex;
        position: relative;
    }

    .week_list li .week_panel ul li .box_item ol>li>label span {
        padding: 4px 15px;
        font-size: 11px;
        line-height: 14px;
    }

    .week_list li .week_panel ul li .box_item ol>li>label a {
        background: #dbdbdb;
        color: #121525;
        padding: 3px 8px;
        font-size: 9px;
        line-height: 12px;
        border-radius: 0 12px 12px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .week_list li .week_panel ul li .box_item ol li i {
        padding: 5px 3px;
        color: #5a5a5a;
        cursor: pointer;
    }

    .week_list .box_item ol li .dropdown_wpr.small {
        min-width: 60px;
        padding: 0;
        left: 100%;
        right: auto;
    }

    .week_list .box_item ol li .dropdown_wpr ul li {
        border: 0;
    }

    .week_list .dropdown_wpr {
        min-width: 150px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        padding: 15px;
        margin-bottom: 10px;
        z-index: 4;
    }

    .week_list .dropdown_wpr:before {
        display: none;
    }

    .week_list .dropdown_wpr .group_item .input_label {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 6px;
        display: block;
    }

    .week_list .dropdown_wpr .group_item .field_wpr input {
        height: 30px;
        font-size: 11px;
        padding: 0 10px;
    }

    .workout_card li {
        display: flex;
        justify-content: flex-start;
        /* align-items: flex-start; */
        gap: 5px;
        margin-bottom: 20px;
    }

    .workout_card li .wk_item {
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 8px;
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        position: relative;
    }

    .workout_card li .period {
        flex: 0 0 55px;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 5px;
    }

    .workout_card li .period .form_grp :deep(.multiselect) {
        /* min-height: 40px; */
        min-height: 66px;
    }

    .workout_card li .period :deep(.multiselect-single-label) {
        padding: 0 15px 0 5px;
    }

    .workout_card li .period :deep(.multiselect-caret) {
        margin-right: 0;
    }

    .workout_card li .period :deep(.multiselect-single-label-text) {
        font-size: 11px;
    }

    .workout_card li .wk_item img {
        width: 100px;
        height: auto;
        object-fit: cover;
    }

    .workout_card .card_details {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
    }

    .workout_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .workout_card li span {
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
    }

    .workout_card li span i {
        font-size: 9px;
        margin-right: 5px;
    }

    .modal.workout_modal .modal_body {
        padding-top: 0;
    }

    .workout_list {
        background: #f5f5f5;
        border-bottom: 1px solid #eaeaea;
        margin: 0 -45px;
        padding: 40px 45px 20px 45px;
    }

    .smart_weeklist .add_btn {
        font-size: 13px;
        line-height: 16px;
    }

    .smart_weeklist .add_btn i {
        margin-right: 5px;
        font-size: 9px !important;
    }

    .smart_weeklist>ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .smart_weeklist>ul .week_info {
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        padding: 5px 10px 20px 10px;
    }

    .smart_weeklist>ul .week_info>li {
        padding: 5px;
        width: 25%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .smart_weeklist>ul .week_info li h6 {
        font-size: 9px;
        line-height: 11px;
        color: #121525;
        font-weight: 400;
        margin: 5px 0;
    }

    .smart_weeklist>ul .week_info li .box_item {
        flex-grow: 1;
        min-height: 70px;
        background: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
    }

    .smart_weeklist>ul .week_info li .box_item p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .smart_weeklist>ul .week_info li .box_item ol {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .smart_weeklist>ul .week_info li .box_item ol li {
        list-style-type: inherit;
        font-size: 9px;
        line-height: 12px;
        padding: 3px 8px;
        font-weight: 400;
        color: #5a5a5a;
        background: #fff;
        border-radius: 9px;
    }

    .exercise_list {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .exercise_item .exercise_info ul {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .exercise_item .exercise_info ul li {
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 6px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        cursor: pointer;
    }

    .exercise_item .exercise_info ul li .video_area {
        width: 45%;
    }

    .video_wpr {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 4px;
        overflow: hidden;
    }

    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }

    .exercise_item .exercise_info ul li .info_wpr {
        width: calc(55% - 15px);
    }

    .exercise_item .exercise_info ul li .info_wpr h4 {
        font-size: 14px;
        line-height: 19px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .exercise_item .exercise_info ul li .info_wpr h6 {
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 5px 0;
    }

    .week_days_list {
        background: #fff;
        position: absolute;
        width: 250px;
        left: 50%;
        top: 100%;
        right: auto;
        transform: translateX(-50%);
        box-shadow: 5px 50px 50px rgba(0, 0, 0, .1);
        border-radius: 8px;
        padding: 10px;
        z-index: 4;
        display: none;
        justify-content: center;
        flex-wrap: wrap;
        gap: 3px;
        margin-top: 10px;
    }

    .week_days_list.active {
        display: flex;
    }

    .week_days_list label .item_wpr {
        width: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 6px 4px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;
    }

    .week_days_list label span {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .week_days_list label .tick_box {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #cdcdcd;
        position: relative;
        margin-top: 7px;
    }

    .week_days_list label .tick_box:after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #5a5a5a;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .week_days_list label.active .item_wpr {
        background: #fbfbfb;
        border: 1px solid #d4d4d4;
    }

    .week_days_list label.active .item_wpr .tick_box:after {
        transform: scale(1);
    }

    .week_days_list .action_wpr {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
    }

    .week_days_list .action_wpr .okay_btn {
        padding: 5px 15px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #fff;
        background: #2f7eed;
        border-radius: 3px;
        cursor: pointer;
    }

    .empty_area {
        border-radius: 10px;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .empty_area h4 {
        font-family: 'Architects Daughter', cursive;
        font-size: 16px;
        line-height: 35px;
        font-weight: 300;
        color: #999;
        max-width: 100%;
        position: relative;
        padding-bottom: 20px;
    }

    .quick_info {
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        width: 180px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: auto;
        bottom: 100%;
        transform: translateX(-50%);
        display: none;
        margin-bottom: 10px;
        font-size: 10px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
    }

    .quick_info:after {
        content: "";
        position: absolute;
        bottom: -6px;
        top: auto;
        left: calc(50% - 5px);
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }

    .week_list li .week_panel ul li .box_item p:hover .quick_info,
    .week_list li .week_panel ul li .box_item ol>li>label:hover .quick_info {
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    :deep(.addblock_section .quote_wpr) {
        padding: 15px 30px;
    }

    /* .workout_info{
        background: #fff;
        border-radius: 8px;
        border: 1px solid #e9e9e9;
    } */
    .inline-edit {
        display: flex;
        align-items: center;
    }
    .workout_info .inline-edit h3{
        padding: 0;
        margin: 0 auto 0 0;
        font-size: 19px;
        line-height: 26px;
    }
    .workout_info .inline-edit .rename_fld{
        padding-right: 46px;
        position: relative;
        margin-right: auto;
    }
    .workout_info .inline-edit input {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
        background: #f0f5ff;
        border-radius: 5px;
    }
    .workout_info .description{
        background: #fff;
        padding: 0 15px;
        border-radius: 5px;
        border: 0;
        position: absolute;
        right: 20px;
        top: 100%;
        z-index: 2;
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .workout_info .description .field_wpr{
        background: #fff;
    }
    .workout_info .description.show{
        border: 1px solid #f5f5f5;
        box-shadow: 0 1px 20px rgb(0,0,0,0.15);
        max-height: 500px;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
        display: inline-table;
    }
    .show_hide_btn{
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: pointer;
        /* float: right; */
        padding: 7px;
    }
    .inline-edit button.save_btn {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
        text-wrap: nowrap;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    
    .inline-edit .pp-label{
        padding: 5px 10px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 9px;
        line-height: 10px;
        font-weight: 500;
        text-transform: uppercase;
        position: relative;
        margin: 0 15px 0 0;
    }
    .inline-edit .setting_btn{
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }
    @keyframes smoothMove {
        0% {
            bottom: 30%;
            opacity: 0;
        }

        10% {
            bottom: 30%;
            opacity: 0.5;
        }

        100% {
            bottom: 100%;
            opacity: 1;
        }
    }

    @media(max-width: 1199px) {
        .info_bar {
            margin-bottom: 30px;
        }
        .info_bar .tutorial_btn{
            left: 50%;
            transform: translateX(-50%);
            top: 100%;
            margin: 0;
        }
        .cover_type {
            margin: 15px -25px;
        }
        .global_setting .tabs_content .section_content:after {
            left: 10px;
            right: 10px;
        }
    }

    @media(max-width: 991px) {
        .prog_edit {
            padding: 20px;
        }
    }

    @media(max-width: 767px) {

        .category_bar input,
        .category_bar .quest_title {
            font-size: 13px;
        }
    }

    @media(max-width: 499px) {
        .day_type li .day_wpr h4 {
            font-size: 11px;
        }

        .sqr_input {
            width: 20px;
            height: 20px;
            font-size: 10px;
        }

        :deep(.dp__main .dp__input) {
            font-size: 10px;
            padding: 0 0 0 25px !important;
        }

        :deep(.dp__main .dp__input_icons) {
            width: 12px;
        }

        .day_type li .day_wpr label {
            width: 40px;
            min-width: auto;
            flex-shrink: 0;
        }

        .time_listing li {
            width: 25%;
        }

        .circumference_list li {
            width: 100%;
        }

        .custom_list li {
            padding: 15px;
        }

        .custom_list li .section_wpr {
            padding: 10px 20px;
        }

        .category_bar .capsule_btn {
            padding: 0 10px 0 0;
        }

        .category_bar .add_btn {
            margin: 0 0 0 10px;
            padding-right: 10px;
        }

        .sub_categories {
            padding: 20px 15px;
        }

        .prog_edit {
            padding: 15px;
        }

        .cover_type {
            margin: 15px -15px;
        }

        .workout_info .inline-edit h3{
            font-size: 15px;
            line-height: 22px;
        }
        .global_setting .tabs_content .section_content:after {
            left: 0;
            right: 0;
        }
    }

    :deep(.cell .dashboard_content) {
        display: block;
    }
</style>
